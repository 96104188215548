import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BrowserButton from "./BrowserButton/BrowserButton";
import classes from "./Footer.module.css";
import chrome from "../../assets/logos/chrome-coloured.png";
import safari from "../../assets/logos/safari-coloured.png";
import firefox from "../../assets/logos/firefox-coloured.png";
import edge from "../../assets/logos/edge-coloured.png";
import brand from "../../assets/logos/brand-coloured.png";
import instagram from "../../assets/logos/instagram.png";
import linkedin from "../../assets/logos/linkedin.png";
import tiktok from "../../assets/logos/tiktok.png";
import usa from "../../assets/images/usa-flag.png";
import esp from "../../assets/images/esp-flag.png";
import bra from "../../assets/images/bra-flag.png";
import fra from "../../assets/images/fra-flag.png";

const browsers = [
  {
    name: "Google Chrome",
    logo: chrome,
    link: "https://chrome.google.com/webstore/detail/opentabs/igeeighenacaciapkehcacnojlegbnpa",
  },
  // {
  //   name: "Apple Safari",
  //   logo: safari,
  //   link: "https://apps.apple.com/es/app/opentabs/id1607542045?l=en&mt=12",
  // },
  // {
  //   name: "Mozilla Firefox",
  //   logo: firefox,
  //   link: "https://addons.mozilla.org/en-US/firefox/addon/opentabs",
  // },
  {
    name: "Microsoft Edge",
    logo: edge,
    link: "https://microsoftedge.microsoft.com/addons/detail/opentabs/oaljniopiimfdkdoppegcoikkaaingln",
  },
];
const socialHandles = [
  {
    name: "instagram",
    logo: instagram,
    link: "https://www.instagram.com/get.opentabs",
  },
  {
    name: "linkedin",
    logo: linkedin,
    link: "https://linkedin.com/company/opentabs-org",
  },
  {
    name: "tiktok",
    logo: tiktok,
    link: "https://www.tiktok.com/@umberto.greco",
  },
];
const currentYear = new Date().getFullYear();

export const Footer = (props) => {
  const { t } = useTranslation();

  const pageLinks1 = [
    { title: t("About_Us"), link: "/about" },
    // { title: t("Contact_us"), link: "https://opentabs.contactin.bio" },
    // {
    //   title: t("FAQ"),
    //   link: "https://opentabs.notion.site/FAQs-56d96d07bcee42e5b0eb135b4fd71619",
    // },
    // { title: t("Shop"), link: "https://shop.opentabs.org" },
    {
      title: t("Privacy"),
      link: "/privacy",
    },
    { title: t("Terms"), link: "/terms" },
    // {
    //   title: "Careers",
    //   link: "https://opentabs.notion.site/Work-at-OpenTabs-2eaf1af25132460399f8d5a857b6f270",
    // },
  ];
  const pageLinks2 = [
    // {
    //   title: t("Financial_reports"),
    //   link: "/financials",
    // },
    // { title: "Games", link: "https://games.opentabs.org" },
    // { title: t("Donate"), link: "https://donorbox.org/opentabs" },
  ];

  const flagStyle = {
    background: `url(${
      props.language === "English" ? usa : props.language === "Español" ? esp : props.language === "Português" ? bra : fra
    }) no-repeat 7px 5px / 24px`,
  };

  return (
    <div className={classes.footerContainer}>
      <div className={classes.contentful}>
        <h2>{t("We_transform_the_everyday_action_of_opening_a_new_tab_into_a_force_of_good")}</h2>
        <p>{t("Because_reversing_climate_change_and_making_a_positive_impact_on_the_world_should_be_easy,_free,_and_accessible_to_everyone.")}</p>
        <div className={classes.browserButtons}>
          {browsers.map((browser, i) => (
            <BrowserButton key={i} name={browser.name} logo={browser.logo} link={browser.link} />
          ))}
        </div>
      </div>
      <div className={classes.linksContainer}>
        <div>
          {pageLinks1.map((page, i) =>
            page.link.startsWith("/") ? (
              <Link to={page.link} key={i} rel="noopener" id="footer-link">
                <span>{page.title}</span>
              </Link>
            ) : (
              <a href={page.link} key={i} rel="noopener" id="footer-link">
                <span>{page.title}</span>
              </a>
            )
          )}
        </div>
        {/* <div>
          {pageLinks2.map((page, i) =>
            page.link.startsWith("/") ? (
              <Link to={page.link} key={i} rel="noopener" id="footer-link">
                <span>{page.title}</span>
              </Link>
            ) : (
              <a href={page.link} key={i} rel="noopener" id="footer-link">
                <span>{page.title}</span>
              </a>
            )
          )}
        </div> */}
      </div>
      <div className={classes.logoContainer}>
        <Link to="/">
          <img src={brand} alt="Brand" />
        </Link>
        <select id="Language-dropdown" onChange={props.onChangeLanguage} value={props.language} style={flagStyle}>
          {props.availableLanguages?.map((language) => (
            <option key={language.code}>{language.name}</option>
          ))}
        </select>
        {/* <div className={classes.socialLinks}>
          {socialHandles.map((socialHandle, i) => (
            <a href={socialHandle.link} key={i} id="footer-link">
              <img src={socialHandle.logo} alt={socialHandle.name} />
            </a>
          ))}
        </div> */}
      </div>
      <div className={classes.copyright}>© {currentYear} OpenTabs</div>
    </div>
  );
};
