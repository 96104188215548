import { useEffect } from "react";
import { coinisPixels, sendCoinisPixel } from "./CoinisTracking";

export const TrackConversion = () => {
  useEffect(() => {
    sendCoinisPixel(coinisPixels.conversion);

    // Close
    window.close();
  }, []);

  return <></>;
};
