import { Link } from "react-router-dom";
import classes from "./Card.module.css";

const Card = (props) => {
  const imageClass = props.imageClassName;
  const imageClassName =
    imageClass === "documentImage"
      ? classes.documentImage
      : imageClass === "speakerImage"
      ? classes.speakerImage
      : classes.shieldImage;

  return props.link.startsWith("/") ? (
    <Link to={props.link} className={classes.anchorColor}>
      <div className={classes.container}>
        <img src={props.image} alt={imageClass} className={imageClassName} />
        <div className={classes.content}>
          <h3 className="fadeUpAnimation" data-scroll>
            {props.title}
          </h3>
          <p className="fadeUpAnimation" data-scroll>
            {props.description}
          </p>
        </div>
      </div>
    </Link>
  ) : (
    <a className={classes.anchorColor} href={props.link} rel="noopener">
      <div className={classes.container}>
        <img src={props.image} alt={imageClass} className={imageClassName} />
        <div className={classes.content}>
          <h3 className="fadeUpAnimation" data-scroll>
            {props.title}
          </h3>
          <p className="fadeUpAnimation" data-scroll>
            {props.description}
          </p>
        </div>
      </div>
    </a>
  );
};

export default Card;
