import classes from "./TermsOfServices.module.css";

export const TermsOfServices = () => {
  return (
    <div className={classes.container}>
      <h1>Our Terms of Service</h1>
      <div>
        <p>Terms of Service</p>
        <p>
          We strive to maintain a website that is clear, honest, and easy to
          use.
        </p>
        <p>Last updated: June 17, 2020</p>
      </div>
      <div>
        <p>
          Greco Paro Association is the not-for-profit creator and owner of
          OpenTabs.
        </p>
      </div>
      <div>
        <p>
          Below are our Terms and Conditions of Use ("Terms") that govern
          OpenTabs’ relationship with its users and others who interact with
          opentabs.org, app.opentabs.org, or other *.opentabs.io subdomains or
          subdirectories (collectively, the "Website") and any information,
          text, graphics, photos or other materials uploaded, downloaded or
          appearing on the Website (collectively referred to as "Content"). This
          document applies to OpenTabs ("Greco Paro", “Greco Paro Association",
          "OpenTabs", "us", "our", or "we"), and by using the Website in any
          way, you agree to these Terms in their entirety.
        </p>
      </div>
      <div>
        <p>If you have any questions, please contact us.</p>
      </div>
      <div>
        <p>Reproduction and Display of Content</p>
        <p>
          OpenTabs, to the best of its knowledge, does not reproduce or display
          any content to which OpenTabs does not have the right to reproduce or
          display.
        </p>
      </div>
      <div>
        <p>
          Any photos or written content reproduced from other sources are photos
          or written content that were explicitly licensed with a Creative
          Commons license for commercial use at the time of their obtainment.
        </p>
      </div>
      <div>
        <p>
          All users must agree to owning all rights to their submitted content
          and give OpenTabs the right to reproduce and display that content on
          the website and in all marketing material. User-submitted content is
          reproduced and displayed on good faith that the user who submitted the
          content owned all rights to the content.
        </p>
      </div>
      <div>
        <p>
          If at any time a the owner of any content reproduced or displayed on
          the Website requests that the owner's content be removed from the
          Website, OpenTabs will comply with that request within fourteen (14)
          days of receiving the request. OpenTabs may require evidence of the
          user's ownership of the material prior to complying with the request.
        </p>
      </div>
      <div>
        <p>Referral Program</p>
        <p>
          For the Referral Program, OpenTabs will distribute "Referral Links" to
          the OpenTabs community. A "Referral Link" is a unique URL for you to
          distribute to eligible friends, family and other third parties (each,
          a "Referred User"). If a Referred User uses your Referral Link to sign
          up and become a user of OpenTabs, then the Referred User shall be
          considered a "Qualifying User" and you will be eligible for the
          referral reward. OpenTabs will at its sole discretion determine which
          Referred Users are approved as Qualifying Users on OpenTabs.
        </p>
      </div>
      <div>
        <p>
          Referrals Links should only be used for personal and non-commercial
          purposes, and only shared with personal connections that will
          appreciate receiving these invitations.
        </p>
      </div>
      <div>
        <p>Disclaimers and Limitations of Liability</p>
        <p>
          Please read this section carefully because it limits the liability of
          OpenTabs and its parents, subsidiaries, affiliates, related companies,
          officers, directors, employees, agents, representatives, partners, and
          licensors (collectively, the "OpenTabs Entities"). Each of the
          subsections below only applies up to the maximum extent permitted
          under applicable law. Some jurisdictions do not allow the disclaimer
          of implied warranties or the limitation of liability in contracts, and
          as a result the contents of this section may not apply to you. Nothing
          in this section is intended to limit any rights you may have which may
          not be lawfully limited.
        </p>
      </div>
      <div>
        <p>A. The Website is Available "AS-IS"</p>
        <p>
          Your access to and use of the Website or any Content are at your own
          risk. You understand and agree that the Website is provided to you on
          an "AS IS" and "AS AVAILABLE" basis. Without limiting the foregoing,
          to the maximum extent permitted under applicable law, THE OPENTABS
          ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR
          IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT.
        </p>
      </div>
      <div>
        <p>
          The OpenTabs Entities make no warranty and disclaim all responsibility
          and liability for: (i) the completeness, accuracy, availability,
          timeliness, security or reliability of the Website or any Content;
          (ii) any harm to your computer system, loss of data, or other harm
          that results from your access to or use of the Website or any Content;
          (iii) the deletion of, or the failure to store or to transmit, any
          Content and other communications maintained by the Website; and (iv)
          whether the Website will meet your requirements or be available on an
          uninterrupted, secure, or error-free basis. No advice or information,
          whether oral or written, obtained from the OpenTabs Entities or
          through the Website, will create any warranty not expressly made
          herein.
        </p>
      </div>
      <div>
        <p>B. Links</p>
        <p>
          The Website may contain links to third-party websites or resources.
          You acknowledge and agree that the OpenTabs Entities are not
          responsible or liable for: (i) the availability or accuracy of such
          websites or resources; or (ii) the content, products, or services on
          or available from such websites or resources. Links to such websites
          or resources do not imply any endorsement by the OpenTabs Entities of
          such websites or resources or the content, products, or services
          available from such websites or resources. You acknowledge sole
          responsibility for and assume all risk arising from your use of any
          such websites or resources.
        </p>
      </div>
      <div>
        <p>C. Limitation of Liability</p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE OPENTABS
          ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
          WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
          GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS
          TO OR USE OF OR INABILITY TO ACCESS OR USE THE WEBSITE; (ii) ANY
          CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE WEBSITE, INCLUDING
          WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
          OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE
          WEBSITE; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
          TRANSMISSIONS OR CONTENT.
        </p>
      </div>
      <div>
        <p>
          IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE OPENTABS ENTITIES
          EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE
          AMOUNT YOU PAID OPENTABS, IF ANY, IN THE PAST SIX MONTHS FOR THE
          WEBSITE GIVING RISE TO THE CLAIM.
        </p>
      </div>
      <div>
        <p>
          THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
          LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
          (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE OPENTABS
          ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND
          EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
          ESSENTIAL PURPOSE.
        </p>
      </div>
      <div>
        <p>Changes to Terms</p>
        <p>
          OpenTabs may change its Terms from time to time, and in OpenTabs’ sole
          discretion. OpenTabs encourages visitors to frequently check this page
          for any changes to its Terms. Your continued use of this site after
          any change in these Terms will constitute your acceptance of such
          change.
        </p>
      </div>
    </div>
  );
};
