import { useTranslation } from "react-i18next";
import { locomotiveScroll as scroll } from "../../pages/Home/Home";
import classes from "./Description.module.css";
import browserWindow2 from "../../assets/images/browserWindow-2.png";

export const Description = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.descriptionContainer}>
        <div className={classes.descriptionElement}>
          <div className="fadeUpAnimation" data-scroll>
            <h2>{t("What_is")}</h2>
            <h2>{t("OpenTabs?")}</h2>
          </div>
          <p className="fadeUpAnimation" data-scroll>
            {t(
              "A_browser_extension_that_changes_your_new_tab_into_something_that_can_actually_make_a_difference."
            )}
          </p>
        </div>
        <img
          src={browserWindow2}
          alt="browser-window"
          className={classes.browserWindow}
          data-scroll
          data-scroll-delay={scroll.delay}
          data-scroll-speed={scroll.speed}
        />
      </div>
    </div>
  );
};
