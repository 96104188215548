import { useEffect } from "react";
import { TermsOfServices } from "../../components";
const customStyle = {
  position: "absolute",
  left: "0",
  top: "0",
};

let timer1;
let timer2;
let countForBadge = 0;
let countForPopup = 0;

const rectangleAdSecondary = <div data-fuse="22622348322"></div>;

export const SecondaryAd = () => {
  useEffect(() => {
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.remove();
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        clearInterval(timer1);
      }
    });
    timer2 = setInterval(() => {
      countForBadge++;
      const ele = document.getElementById("cookiescript_badge");
      if (ele) {
        ele.remove();
        clearInterval(timer2);
      }
      if (countForBadge > 10000) {
        clearInterval(timer2);
      }
    });
  }, []);

  return (
    <>
      <div style={customStyle}>{rectangleAdSecondary}</div>
      <div style={{ marginTop: "260px" }}>
        <TermsOfServices />
      </div>
    </>
  );
};
