const isAdBlockerEnabled = window.canRunAds !== true;

export const Message = () => {
  const channel = localStorage.channel ? localStorage.channel : "";
  let data = { isAdBlocked: false, channel };
  if (isAdBlockerEnabled) data = { isAdBlocked: true, channel };
  window.parent.postMessage(data, "*");

  return null;
};
