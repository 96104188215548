/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import imagesLoaded from "imagesloaded";
import LocomotiveScroll from "locomotive-scroll";
import "../../locomotive-scroll.css";
import { Navbar } from "../../containers";
import { Logo, Modal, PrivacyPolicy, Footer } from "../../components";
import { languages, userDeviceDetails, isMobile, getBrowser, getTranslateY } from "../Home/Home";
import { Helmet } from "react-helmet";
import classes from "./Privacy.module.css";

export const Privacy = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [browserType, setBrowserType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCode = localStorage.getItem("i18nextLng") || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find((language) => language.code === selectedCode).name);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const locomotiveScrollRef = useRef();

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: locomotiveScrollRef.current,
      smooth: true,
    });

    imagesLoaded(locomotiveScrollRef.current, { background: true }, function () {
      scroll.update();
    });

    const navbarScrollVisibilityInterval = setInterval(() => {
      const refStyles = locomotiveScrollRef.current?.style?.msTransform || "";
      const translateY = getTranslateY(refStyles);
      setIsNavbarVisible(translateY >= 80);
    }, 2000);

    return () => {
      clearInterval(navbarScrollVisibilityInterval);
      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    setIsMobileDevice(isMobile(userDeviceDetails));
    if (!isMobileDevice) {
      setBrowserType(getBrowser(userDeviceDetails));
    }
  }, []);

  const changeLanguage = (event) => {
    const targetLanguage = event.target.value;
    setSelectedLanguage(targetLanguage);
    const targetCode = languages.find((language) => language.name === targetLanguage).code;
    i18next.changeLanguage(targetCode);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <Modal show={isModalOpen} close={toggleModal} />
      <div id="locomotiveScrollRef" className={classes.backgroundGradient} ref={locomotiveScrollRef}>
        <Navbar isVisible={isNavbarVisible} isMobile={isMobileDevice} openModal={toggleModal} browser={browserType} />
        <div className={classes.topContainer}>
          <Logo availableLanguages={languages} language={selectedLanguage} onChangeLanguage={changeLanguage} />
        </div>
        <PrivacyPolicy />
        <footer className={classes.fade}>
          <Footer availableLanguages={languages} language={selectedLanguage} onChangeLanguage={changeLanguage} />
        </footer>
      </div>
    </>
  );
};
