import { useTranslation } from "react-i18next";
import classes from "./BrowserButton.module.css";

const BrowserButton = (props) => {
  const { t } = useTranslation();

  return (
    <a href={props.link} className={classes.button}>
      <img src={props.logo} alt={props.name} />
      <div>
        <p className={classes.text}>{t("Download_for")}</p>
        <p className={classes.subtext}>{props.name}</p>
      </div>
    </a>
  );
};

export default BrowserButton;
