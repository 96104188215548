import { colors } from "..";
import tweets from "../../tweets.json";
import CTA from "./CTA";
import graphic from "../assets/graphic-3.png";
import graphic2 from "../assets/graphic-4.png";

function Tweet({ tweet }) {
  return (
    <div className="flex flex-col items-start gap-4 justify-start h-[12rem] rounded-lg px-6 py-4 shrink-0 w-[18rem] border-solid border-black/40 border-[2px]">
      <div className="flex items-center justify-start gap-4">
        <img src={tweet.icon} alt="Profile" className="w-10 h-10 rounded-full" />

        <div className="flex flex-col gap-1">
          <span className={`${colors.heading} text-[14px] font-medium`}>{tweet.title}</span>

          <span className={`${colors.heading} text-xs`}>{tweet.userName}</span>
        </div>
      </div>
      <p className="text-black/90 text-[13px] leading-5">{tweet.text}</p>
    </div>
  );
}

export default function Testimonials() {
  return (
    <section className="mx-auto flex items-center justify-center relative max-w-[1200px] w-full flex-col gap-8 pb-24 p">
      <h1 className={`${colors.heading} my-12 text-[30px] md:text-[42px] font-medium`}>Testimonials</h1>

      <div className="px-8 overflow-scroll max-w-full box-border">
        <div className="flex items-start justify-start gap-10 max-w-full pb-16">
          {tweets.slice(1, 5).map((tweet, index) => (
            <Tweet key={index} tweet={tweet} />
          ))}
        </div>
      </div>

      <h1 className={`${colors.heading} my-12 text-[30px] md:text-[42px] font-medium`}>Ready to Help Us Save the Oceans?</h1>

      <CTA
        style={{
          filter: "drop-shadow(0px 12px 16px rgba(0,0,0,0.5))",
        }}
      />

      <img src={graphic.src} className="absolute left-[-150px] w-[250px] top-[-80px]" />

      <img src={graphic2.src} className="absolute right-[-150px] w-[200px] bottom-[100px]" />
    </section>
  );
}
