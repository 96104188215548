import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { browsers } from "../../components/Introduction/Introduction";
import classes from "./Navbar.module.css";
import wideBrandLogo from "../../assets/logos/brand-coloured.svg";
import shortBrandLogo from "../../assets/logos/brand-coloured.png";

export const Navbar = (props) => {
  const { t } = useTranslation();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setShowNavbar(window.pageYOffset > 80);
  };

  const downloadButton = props.isMobile ? (
    <button className={classes.downloadButton} onClick={props.openModal}>
      {t("download_now")}
    </button>
  ) : (
    browsers
      .filter((browser) => browser.name === props.browser)
      .map((browser, i) => (
        <a
          className={classes.shortWindowDownloadLink}
          href={browser.link}
          rel="noopener"
          key={i}
        >
          <button className={classes.downloadButton}>
            {t("download_now")}
          </button>
        </a>
      ))
  );

  return (
    <div
      data-scroll
      data-scroll-sticky
      data-scroll-target="#locomotiveScrollRef"
      style={{
        visibility: props.isMobile
          ? showNavbar
            ? "visible"
            : "hidden"
          : props.isVisible
          ? "visible"
          : "hidden",
          opacity: props.isMobile
          ? showNavbar
            ? "1"
            : "0"
          : props.isVisible
          ? "1"
          : "0",
      }}
      className={classes.container}
    >
      <div className={classes.logoContainer}>
        <Link to="/">
          <img
            src={wideBrandLogo}
            alt="brand logo"
            className={classes.wideLogo}
            loading="lazy"
          />
          <img
            src={shortBrandLogo}
            alt="brand logo"
            height="40"
            className={classes.shortLogo}
            loading="lazy"
          />
        </Link>
      </div>
      <div className={classes.linkContainer}>
        <Link to="/about" className={classes.textLink}>
          {t("our_mission")}
        </Link>
        {browsers
          .filter((browser) => browser.name === props.browser)
          .map((browser, i) => (
            <a className={classes.buttonLink} href={browser.link} key={i}>
              <button className={classes.browserButton}>
                {t("download_now")}
              </button>
            </a>
          ))}
        {downloadButton}
      </div>
    </div>
  );
};
