import { useTranslation } from "react-i18next";
import classes from "./Team.module.css";
import umbertoProfile from "../../assets/images/umberto-profile.png";
import catharinaProfile from "../../assets/images/catharina-profile.png";

export const Team = () => {
  const { t } = useTranslation();

  const teamPlayers = [
    {
      name: "Umberto Greco",
      position: t("co-founder_m"),
      image: umbertoProfile,
      about: t(
        "Umberto_founded_OpenTabs_while_studying_at_university_with_the_goal_of_redesigning_the_internet_for_social_impact_and_improving_the_world_in_a_sustainable_way._He_developed_a_passion_for_sustainable_development_after_growing_up_around_the_world_in_countries_including_Sudan,_North_Korea,_Zimbabwe,_and_Malawi."
      ),
    },
    {
      name: "Catharina Doria",
      position: t("co-founder_f"),
      image: catharinaProfile,
      about: t(
        "Catharina_always_had_a_passion_for_ways_in_which_technology_could_be_used_for_social_good._At_the_age_of_16,_she_developed_a_viral_app_to_map_sexual_harassment_in_Brazil_to_makes_streets_safer_for_women._She_has_given_talks_at_different_tech_conferences_to_discuss_the_importance_of_women_in_tech_and_has_worked_at_Twitter_and_Pinterest._She_is_currently_studying_for_a_Master's_Degree_in_Business_Analytics_&_Big_Data."
      ),
    },
  ];

  return (
    <div className={classes.flexContainer}>
      {teamPlayers.map((player, i) => (
        <div className={classes.profileContainer} key={i}>
          <div className={classes.profileHeader}>
            <img src={player.image} alt="profile" />
            <div className={classes.detailsContainer}>
              <h2 className="fadeUpAnimation" data-scroll>
                {player.name}
              </h2>
              <div
                className={`fadeUpAnimation ${classes.positionContainer}`}
                data-scroll
              >
                <p>{player.position}</p>
              </div>
            </div>
          </div>
          <div className={classes.aboutSection}>
            <p className="fadeUpAnimation" data-scroll>
              {player.about}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
