/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

export const JointeroAd = () => {
  return (
    <iframe
      width="100%"
      src="https://s0.2mdn.net/sadbundle/7863322908415681973/728x90.html"
      class="rectangle-iframe"
    ></iframe>
  );
};
