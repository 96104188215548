import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./Abstraction.module.css";
import monkeyOnTree from "../../assets/images/monkey-climbing-tree.png";
import birdOnTree from "../../assets/images/bird-on-tree.png";
import rainforestTrust from "../../assets/logos/Rainforest-Trust.png";

const rainforestTrustLink = "https://www.rainforesttrust.org";

export const Abstraction = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.descriptionContainer}>
      <img
        src={birdOnTree}
        alt="bird-on-tree"
        className={classes.birdTreeImage}
      />
      <div className={classes.descriptionElement}>
        <a href={rainforestTrustLink} rel="noopener noreferrer">
          <img
            src={rainforestTrust}
            alt="Rainforest Trust"
            className={classes.rainforestLogo}
          />
        </a>
        <h2 className="fadeUpAnimation" data-scroll>
          "{t("Planting trees is good. Saving existing forests is better.")}"
        </h2>
        <div className={classes.textContainer}>
          <p>
            <span className="fadeUpAnimation" data-scroll>
              {t(
                "With_OpenTabs,_we_made_an_active_decision_to_save_existing_trees_rather_than_planting_new_ones._You_might_be_wondering_why_this_matters_or_why_we_made_this_decision_when_other_well-known_companies_have_chosen_to_plant_new_ones."
              )}
              <Link to="/about" aria-label="about us">
                <button>{t("more_about_us")}</button>
              </Link>
            </span>
          </p>
          <p className="fadeUpAnimation" data-scroll>
            {t(
              "To_put_it_simply,_compelling_studies_show_that_planting_trees_won’t_be_enough_to_tackle_climate_change_and_that_efforts_on_rainforest_conservation_lead_to_a_more_sustainable_and_lasting_change."
            )}
          </p>
        </div>
        <Link to="/about" aria-label="about us">
          <button>{t("more_about_us")}</button>
        </Link>
      </div>
      <img
        src={monkeyOnTree}
        alt="monkey-climbing-tree"
        className={classes.monkeyTreeImage}
      />
    </div>
  );
};
