import logo from "../assets/logo-white.svg";
import bg from "../assets/hero-bg.png";

export const Footer = () => {
  return (
    <div
      className="relative w-full"
      style={{
        backgroundImage: `url(${bg})`,
        filter: "hue-rotate(270deg)",
      }}
    >
      <div className="mx-auto max-w-[1200px] flex items-center justify-between py-8 px-8">
        <a href="/">
          <img src={logo} alt="logo" className="h-[24px] md:h-[38px] object-contain" />
        </a>

        <span className="text-white text-xs md:text-sm">All rights reserved &copy; OceanHero 2024</span>
      </div>
    </div>
  );
};

export default Footer;
