import { useTranslation } from "react-i18next";
import classes from "./Recognition.module.css";
import hatchColab from "../../assets/logos/hatch-colab.svg";
import jacobs from "../../assets/logos/jacobs.svg";
import ieUniversity from "../../assets/logos/ie-university.svg";
import google from "../../assets/logos/google.svg";
import santanderx from "../../assets/logos/santanderx.svg";

export const Recognition = () => {
  const { t } = useTranslation();

  const organiztions = [
    {
      title: t("Finalist"),
      description: t("Social Tech Accelerator"),
      image: hatchColab,
      link: "https://hatchcolab.ch",
    },
    {
      title: t("Finalist"),
      description: t("Jacobs Startup Competition"),
      image: jacobs,
      link: "https://www.jacobs-startup.com",
    },
    {
      title: t("Winner"),
      description: t("Sustainable Entrepreneurship"),
      image: ieUniversity,
      link: "https://www.ie.edu/business-school/news-and-events/whats-going-on/ie-green-week-sustainable-entrepreneurship-waste-management",
    },
    {
      title: t("Recipient"),
      description: t("Google AdGrant"),
      image: google,
      link: "https://www.google.com/nonprofits",
    },
    {
      title: t("Participant"),
      description: t("Young People With Solutions"),
      image: santanderx,
      link: "https://en.explorerbyx.org",
    },
  ];

  return (
    <div className={classes.recognition}>
      <div className="fadeUpAnimation" data-scroll>
        <h4>{t("A_Recognized_Non-Profit_Startup")}</h4>
        <div className={classes.carouselContainer}>
          {organiztions.map((organiztion, i) => (
            <div className={classes.carouselItem} key={i}>
              <a href={organiztion.link} rel="noopener">
                <div className={classes.brandLogo}>
                  <img src={organiztion.image} alt="brand-logo" />
                </div>
                <p className={classes.recognitionTitle}>{organiztion.title}</p>
                <p className={classes.recognitionDescription}>
                  {organiztion.description}
                </p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
