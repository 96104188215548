import { useTranslation } from "react-i18next";
import Backdrop from "./Backdrop/Backdrop";
import classes from "./Modal.module.css";
import cross from "../../assets/images/cross.svg";

// const googleFormURL =
//   "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfN2ZkwXTUB7d6rQfOSG1Uroqav21itGQlPV-gCb5IZ325Tkg/formResponse?";
// const googleFormEmailField = "entry.1260411098";

const endPoint = "https://app.opentabs.org/api/users/sendExtensionDetails";
export const Modal = (props) => {
  const { t } = useTranslation();

  const emailSignup = async (event) => {
    event.preventDefault();
    const userEmail = event.target.useremail.value;
    try {
      const response = await fetch(endPoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
        }),
      });
      const res = await response.json();
      if (res?.msg && res?.msg.includes("Extension details")) {
        alert(
          "Thank you for signing up! You will soon be receiving an email from us with more information."
        );
      } else if (res?.errors && res?.errors.length > 0) {
        alert(res?.errors[0].msg);
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
    props.close();
  };

  return (
    <>
      <div
        style={{
          transform: props.show
            ? "translate(-50%,-50%)"
            : "translate(-50%,-200vh)",
          opacity: props.show ? "1" : "0",
        }}
        className={classes.Modal}
      >
        <img src={cross} alt="close" loading="lazy" onClick={props.close} />
        <p className={classes.headerText}>
          {t(
            "Send_yourself_a_link_to_the_extension_so_you_can_download_it_on_your_computer!"
          )}
        </p>
        <form
          autoComplete="true"
          className={classes.signupForm}
          onSubmit={emailSignup}
        >
          <input
            name="useremail"
            type="email"
            placeholder={t("Your_email")}
            required
          />
          <button type="submit">{t("submit")}</button>
        </form>
        {/* <p className={classes.subText}>
          {t(
            "We_will_only_use_your_email_to_send_you_the_download_link_for_your_computer_and_we_will_not_store_it_in_any_database._We're_a_privacy-friendly_nonprofit_interested_in_making_an_impact,_not_in_your_data_:)"
          )}
        </p> */}
      </div>
      <Backdrop show={props.show} close={props.close} />
    </>
  );
};
