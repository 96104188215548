import { colors } from "..";
import tweets from "../../tweets.json";
import CTA from "./CTA";

function Tweet({ tweet }) {
  return (
    <div className="flex flex-col items-start justify-start h-[13.5rem] rounded-lg px-6 py-4 shrink-0 w-[18rem] border-solid border-black border-[1px] border-b-[8px]">
      <div className="flex items-center justify-start gap-4">
        <img src={tweet.icon} alt="Profile" className="w-10 h-10 rounded-full" />

        <div className="flex flex-col gap-1">
          <span className={`${colors.heading} text-[14px] font-medium`}>{tweet.title}</span>

          <span className={`${colors.heading} text-xs`}>{tweet.userName}</span>
        </div>
      </div>
      <p className="text-black/90 text-[13px] leading-5">{tweet.text}</p>
    </div>
  );
}

export default function Testimonials() {
  return (
    <section className="mx-auto flex items-center justify-center flex-col gap-8 pb-24 p">
      <h1 className={`${colors.heading} text-[30px] md:text-[42px] font-bold`}>Testimonials</h1>

      <div className="px-8 overflow-scroll max-w-full box-border mt-8">
        <div className="flex items-start justify-start gap-10 max-w-full pb-16">
          {tweets.slice(1, 5).map((tweet, index) => (
            <Tweet key={index} tweet={tweet} />
          ))}
        </div>
      </div>

      <CTA className="scale-[0.7] -mt-4" />
    </section>
  );
}
