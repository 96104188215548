export const numberZero = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150.34 191.73"
    className="numberSvg"
  >
    <g className="digit-0">
      <path d="M.5,95.87C.5,39.28,27.75.5,75.17.5s74.67,38.78,74.67,95.37-27.25,95.36-74.67,95.36S.5,152.46.5,95.87Zm107.42,0c0-35.37-10.74-54.5-32.75-54.5S42.42,60.5,42.42,95.87s10.74,54.49,32.75,54.49S107.92,131.23,107.92,95.87Z" />
    </g>
  </svg>
);

export const numberOne = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.73 184.4"
    className="numberSvg"
  >
    <g className="digit-1">
      <path d="M85.23.5V183.9H43.31V44.78l-32.48,8.9L.61,17.79,48.55.5Z" />
    </g>
  </svg>
);

export const numberTwo = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 127.68 188.06"
    className="numberSvg"
  >
    <g className="digit-2">
      <path d="M2.47,157.17,64,93.25c10.74-11,18.6-21.75,18.6-32.49,0-11.53-7.34-19.39-19.65-19.39-12.84,0-22,8.38-27,19.91L.63,40.58C12.42,13.34,37.05.5,62.47.5,95.21.5,124.56,22,124.56,58.92c0,22-11.79,40.88-28.56,57.64L65.61,147.22h61.57v40.34H2.47Z" />
    </g>
  </svg>
);

export const numberThree = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132.13 188.06"
    className="numberSvg"
  >
    <g className="digit-3">
      <path d="M131.63,126.26c0,40.34-31.44,61.3-66.81,61.3-27.77,0-52.92-12.31-64.19-39.3l36.15-21c4.2,12.06,12.06,19.39,28,19.39,17.29,0,24.89-9.17,24.89-20.43s-7.6-20.44-24.89-20.44H56.17L40.19,82,73.46,39.8H6.39V.5h117.9V34.56L92.59,74.91C115.12,82.77,131.63,100.84,131.63,126.26Z" />
    </g>
  </svg>
);

export const numberFour = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 145.1 184.4"
    className="numberSvg"
  >
    <g className="digit-4">
      <path d="M144.6,154.55H126.26V183.9H83.81V154.55H.5V115.78L58.92.5H104L45.56,115.78H83.81V76.48h42.45v39.3H144.6Z" />
    </g>
  </svg>
);

export const numberFive = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 133.44 188.06"
    className="numberSvg"
  >
    <g className="digit-5">
      <path d="M132.94,126.26c0,40.34-31.44,61.3-66.81,61.3-29.08,0-54.23-12.31-65.5-39.3l36.15-21c4.2,12.06,12.06,19.39,29.35,19.39S91,137.52,91,126.26s-7.6-20.44-23.58-20.44H10.06L17.66.5H123.77V39.8H57L55.12,65H70.06C104.12,65,132.94,85.91,132.94,126.26Z" />
    </g>
  </svg>
);

export const numberSix = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 137.24 188.06"
    className="numberSvg"
  >
    <g className="digit-6">
      <path d="M136.74,122.85c0,38-27.78,64.71-68.12,64.71C28,187.56.5,160.84.5,122.85c0-14.15,3.93-27,10.74-37.2L67.31.5h48.2L75.69,58.4C112.11,61.28,136.74,87.22,136.74,122.85Zm-41.92,0c0-16-12.32-25.41-26.2-25.41-14.15,0-26.2,9.17-26.2,25.41s12,25.41,26.2,25.41C82.5,148.26,94.82,138.83,94.82,122.85Z" />
    </g>
  </svg>
);

export const numberSeven = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 121.52 184.4"
    className="numberSvg"
  >
    <g className="digit-7">
      <path d="M121,.5V35.61L61.28,183.9H16.74l57.64-143H.5V.5Z" />
    </g>
  </svg>
);

export const numberEight = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135.67 191.73"
    className="numberSvg"
  >
    <g className="digit-8">
      <path d="M135.17,136c0,33.54-24.11,55.28-67.34,55.28S.5,169.49.5,136c0-18.08,8.38-34.06,23.84-44C13.6,83.55,6.79,71.5,6.79,55,6.79,19.36,34,.5,67.83.5S128.88,19.36,128.88,55c0,16.51-6.81,28.56-17.56,36.95C126.78,101.89,135.17,117.87,135.17,136Zm-41.92-6c0-13.89-10.75-22-25.42-22s-25.41,8.12-25.41,22,10.74,22,25.41,22S93.25,143.81,93.25,129.93ZM48.71,57.61c0,10.22,7.33,17.82,19.12,17.82S87,67.83,87,57.61,79.62,39.8,67.83,39.8,48.71,47.4,48.71,57.61Z" />
    </g>
  </svg>
);

export const numberNine = (
  <svg
    height="74px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 137.24 188.06"
    className="numberSvg"
  >
    <g className="digit-9">
      <path d="M126,102.42,69.93,187.56H21.72l39.82-57.9C25.13,126.78.5,100.84.5,65.21.5,27.22,28.27.5,68.62.5c40.61,0,68.12,26.72,68.12,64.71C136.74,79.36,132.81,92.2,126,102.42ZM68.62,90.63c14.15,0,26.2-9.17,26.2-25.42s-12-25.41-26.2-25.41c-13.89,0-26.2,9.43-26.2,25.41S54.73,90.63,68.62,90.63Z" />
    </g>
  </svg>
);
