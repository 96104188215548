import { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Projection.module.css";
import whatsappColoured from "../../assets/logos/whatsapp-coloured.svg";
import whatsapp from "../../assets/logos/whatsapp.svg";
import netflix from "../../assets/logos/netflix.svg";
import netflixColoured from "../../assets/logos/netflix-coloured.svg";
import ecosia from "../../assets/logos/ecosia.svg";
import ecosiaColoured from "../../assets/logos/ecosia-coloured.svg";
import tiktok from "../../assets/logos/tiktok.svg";
import tiktokColoured from "../../assets/logos/tiktok-coloured.svg";

const organizations = [
  { name: "whatsapp", image: whatsapp, colouredImage: whatsappColoured },
  {
    name: "netflix",
    image: netflix,
    colouredImage: netflixColoured,
    width: "151px",
  },
  {
    name: "ecosia",
    image: ecosia,
    colouredImage: ecosiaColoured,
    width: "151px",
    height: "90px",
  },
  {
    name: "tiktok",
    image: tiktok,
    colouredImage: tiktokColoured,
    width: "162px",
  },
];

export const Projection = () => {
  const { t } = useTranslation();
  const [selectedProjection, setSelectedProjection] = useState("whatsapp");
  const value =
    selectedProjection === "whatsapp"
      ? `32 ${t("billion")}`
      : selectedProjection === "netflix"
      ? `3 ${t("billion")}`
      : selectedProjection === "ecosia"
      ? `245 ${t("million")}`
      : `11 ${t("billion")}`;

  const updateProjection = (event) => {
    const clickedProjection = event.target.id;
    setSelectedProjection(clickedProjection);
  };

  return (
    <>
      <div className={classes.componentContainer}>
        <div className={classes.componentHeading}>
          <h2 className="fadeUpAnimation" data-scroll>
            {t("The_power_of_the_many.")}
          </h2>
          <p className="fadeUpAnimation" data-scroll>
            {t(
              "If_OpenTabs_were_to_grow_to_have_the_same_number_of_users_as..."
            )}
          </p>
        </div>
        <div className={classes.buttonContainer}>
          {organizations.map((organization, i) => (
            <div
              style={{
                background:
                  organization.name === selectedProjection
                    ? "rgba(255, 255, 255, 0.8)"
                    : "",
              }}
              key={organization.name}
              id={organization.name}
              className={classes.buttonElement}
              onClick={updateProjection}
            >
              <img
                id={organization.name}
                className={
                  selectedProjection === "ecosia" &&
                  organization.name === selectedProjection
                    ? classes.ecosiaColoured
                    : undefined
                }
                width={organization.width}
                height={organization.height}
                src={
                  organization.name === selectedProjection
                    ? organization.colouredImage
                    : organization.image
                }
                alt={organization.name}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.subContainer}>
        <h1 className="fadeUpAnimation" data-scroll>
          {value} {t("trees")}
        </h1>
        <h2 className="fadeUpAnimation" data-scroll>
          {t("per_month")}
        </h2>
      </div>
    </>
  );
};
