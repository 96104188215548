/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import imagesLoaded from "imagesloaded";
import LocomotiveScroll from "locomotive-scroll";
import "../../locomotive-scroll.css";
import classes from "../Home/Home.module.css";
import { Navbar, Projection } from "../../containers";
import {
  Logo,
  Introduction,
  Recognition,
  Description1,
  Method,
  Abstraction,
  Claim,
  Footer,
  Globe,
  Modal,
  Badge,
  Testimonial,
} from "../../components";

export const languages = [
  { name: "English", code: "en", dir: "ltr", active: true },
  { name: "Español", code: "es", dir: "ltr", active: false },
  { name: "Português", code: "pt", dir: "ltr", active: false },
  { name: "Français", code: "fr", dir: "ltr", active: false },
];

export const locomotiveScroll = { delay: "0.04", speed: "0.5" };
export let userDeviceDetails = navigator.userAgent;

export const isMobile = (userDeviceDetails) => {
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobile = regexp.test(userDeviceDetails);
  return isMobile;
};

export const getBrowser = (userDeviceDetails) => {
  let userAgentString = userDeviceDetails;

  let chromeAgent = userAgentString.indexOf("Chrome") > -1;
  let edgeAgent = userAgentString.indexOf("Edg") > -1;
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Chrome since it also matches Edge
  if (chromeAgent && edgeAgent) chromeAgent = false;
  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  return chromeAgent
    ? "chrome"
    : edgeAgent
    ? "edge"
    : firefoxAgent
    ? "firefox"
    : safariAgent
    ? "safari"
    : "chrome";
};

export const getTranslateY = (string) =>
  string?.includes("matrix3d")
    ? +string?.split(",")?.slice(-3)[0]?.split("-")[1] || 0
    : +string?.split(",")?.slice(-1)[0]?.split(")")[0]?.split("-")[1] || 0;

export const Home1 = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [browserType, setBrowserType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCode = localStorage.getItem("i18nextLng") || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((language) => language.code === selectedCode).name
  );
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const locomotiveScrollRef = useRef();

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: locomotiveScrollRef.current,
      smooth: true,
    });

    imagesLoaded(
      locomotiveScrollRef.current,
      { background: true },
      function () {
        scroll.update();
      }
    );

    const navbarScrollVisibilityInterval = setInterval(() => {
      const refStyles = locomotiveScrollRef.current?.style?.msTransform || "";
      const translateY = getTranslateY(refStyles);
      setIsNavbarVisible(translateY >= 80);
    }, 2000);

    return () => {
      clearInterval(navbarScrollVisibilityInterval);
      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    setIsMobileDevice(isMobile(userDeviceDetails));
    if (!isMobileDevice) {
      setBrowserType(getBrowser(userDeviceDetails));
    }
  }, []);

  const changeLanguage = (event) => {
    const targetLanguage = event.target.value;
    setSelectedLanguage(targetLanguage);
    const targetCode = languages.find(
      (language) => language.name === targetLanguage
    ).code;
    i18next.changeLanguage(targetCode);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <Modal show={isModalOpen} close={toggleModal} />
      <Badge browser={browserType} />
      <div id="locomotiveScrollRef" ref={locomotiveScrollRef}>
        <Navbar
          isVisible={isNavbarVisible}
          isMobile={isMobileDevice}
          openModal={toggleModal}
          browser={browserType}
        />
        <section className={`hero ${classes.backgroundGradient}`}>
          <div className={classes.backgroundTexture}>
            <div className={classes.topContainer}>
              <Logo
                availableLanguages={languages}
                language={selectedLanguage}
                onChangeLanguage={changeLanguage}
              />
              <Introduction
                isMobile={isMobileDevice}
                openModal={toggleModal}
                browser={browserType}
              />
            </div>
            <Recognition />
          </div>
          <div className={classes.transparency}></div>
        </section>
        <section>
          <Description1 />
          <Method />
        </section>
        <section className={classes.gradient}>
          <div className={classes.alignment}>
            <Abstraction />
          </div>
          <div className={classes.backgroundImage}>
            <Globe
              isMobile={isMobileDevice}
              openModal={toggleModal}
              browser={browserType}
            />
            <Projection />
          </div>
          <Testimonial />
          <div className={classes.footerGradientTexture}>
            <Claim />
            <div className={classes.fade}>
              <Footer
                availableLanguages={languages}
                language={selectedLanguage}
                onChangeLanguage={changeLanguage}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
