import { useEffect } from "react";
import Gradient from "../Gradient";
import BottomHero from "./components/BottomHero";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import { CoinisTracking } from "../CoinisTracking";
import Calculator from "./components/Calculator";

const Page = () => {
  useEffect(() => {
    // Set title
    document.title = "OpenTabs";

    // Set the link tags
    const link = document.createElement("link");
    link.rel = "preconnect";
    link.href = "https://fonts.googleapis.com";
    document.head.appendChild(link);

    const link2 = document.createElement("link");
    link2.rel = "preconnect";
    link2.href = "https://fonts.gstatic.com";
    link2.crossOrigin = "true";
    document.head.appendChild(link2);

    const link3 = document.createElement("link");
    link3.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap";
    link3.rel = "stylesheet";
    document.head.appendChild(link3);
  }, []);

  return (
    <main
      className="relative overflow-hidden"
      style={{
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <CoinisTracking landingPageId="5" />
      <Hero />

      <HowItWorks />
      <Calculator />
      <Testimonials />
      <BottomHero />
      <Footer />
      <Gradient gradient={"linear-gradient(160deg, #FFFFFF 0%, #41bab8 160%, #FFFFFF 140%)"} />
    </main>
  );
};

export const colors = {
  heading: "text-[#006548]",
};

export default Page;
