import { useState } from "react";
import classes from "./Ads.module.css";
import uBlockOriginVideo from "../../assets/videos/Ublock-Origin.mp4";
import ghosteryVideo from "../../assets/videos/Ghostery.mp4";
import adBlock from "../../assets/images/adBlock.png";
import adBlockPlus from "../../assets/images/adBlockPlus.png";
import uBlock from "../../assets/images/uBlock.png";
import ghostery from "../../assets/images/ghostery.png";
import adGuard from "../../assets/images/adGuard.png";
import other from "../../assets/images/other.png";

const AD_BLOCKER_1 = "uBlock Origin";
const AD_BLOCKER_2 = "AdBlock Plus";
const AD_BLOCKER_3 = "AdBlock";
const AD_BLOCKER_4 = "Ghostery";
const AD_BLOCKER_5 = "AdGuard";
const AD_BLOCKER_6 = "Other";

const adBlockers = [
  {
    name: AD_BLOCKER_1,
    logo: uBlock,
    video: uBlockOriginVideo,
    description: [
      `On a new tab, click on the uBlock icon, then click the big blue "Power" button.`,
    ],
  },
  {
    name: AD_BLOCKER_2,
    logo: adBlockPlus,
    description: [
      `Unfortunately, ${AD_BLOCKER_2} is not compatible with OpenTabs and there is no way to whitelist our extension.`,
      `Please consider downloading another Adblocker that is compatible.`,
      `uBlock Origin and Ghostery are great options.`,
    ],
  },
  {
    name: AD_BLOCKER_3,
    logo: adBlock,
    description: [
      `Unfortunately, ${AD_BLOCKER_3} is not compatible with OpenTabs and there is no way to whitelist our extension.`,
      `Please consider downloading another Adblocker that is compatible.`,
      `uBlock Origin and Ghostery are great options.`,
    ],
  },
  {
    name: AD_BLOCKER_4,
    logo: ghostery,
    video: ghosteryVideo,
    description: [
      `On a new tab, click on the Ghostery icon, then click "Trust Site" in the lower-left corner.`,
    ],
  },
  {
    name: AD_BLOCKER_5,
    logo: adGuard,
    description: [
      `Unfortunately, ${AD_BLOCKER_5} is not compatible with OpenTabs and there is no way to whitelist our extension.`,
      `Please consider downloading another Adblocker that is compatible.`,
      `uBlock Origin and Ghostery are great options.`,
    ],
  },
  {
    name: AD_BLOCKER_6,
    logo: other,
    description: [
      `Please consider downloading another Adblocker that is compatible.`,
      `uBlock Origin and Ghostery are great options.`,
    ],
  },
];

const redirectToWebstore = () =>
  (window.location.href =
    "https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm");

export const Ads = () => {
  const [activeAdBlocker, setActiveAdBlocker] = useState(AD_BLOCKER_1);

  const activeAdBlockerObj = adBlockers.find(
    (adBlocker) => adBlocker.name === activeAdBlocker
  );

  const changeAdBlocker = (adBlocker) => {
    setActiveAdBlocker(adBlocker);
  };

  return (
    <div className={classes.backgroundGradient}>
      <div className={classes.backgroundTexture}>
        <div className={classes.header}>
          <h1>How do I get my ad blocker to make an exception for OpenTabs?</h1>
        </div>
        <div className={classes.mainContainer}>
          <div className={classes.logoContainer}>
            {adBlockers.map((adBlocker, index) => (
              <div
                className={`${classes.adBlockerLogo} ${
                  adBlocker === activeAdBlockerObj ? classes.selected : ""
                }`}
                onClick={() => changeAdBlocker(adBlocker.name)}
                key={index}
              >
                <img src={adBlocker.logo} alt="logo" />
                <p>{adBlocker.name}</p>
              </div>
            ))}
          </div>
          <div className={classes.bodyContainer}>
            <div className={classes.windowVideo}>
              <video
                muted
                autoPlay
                loop
                style={{
                  display: activeAdBlocker === AD_BLOCKER_1 ? "block" : "none",
                }}
              >
                <source src={uBlockOriginVideo} type="video/mp4" />
              </video>
              <video
                muted
                autoPlay
                loop
                style={{
                  display: activeAdBlocker === AD_BLOCKER_4 ? "block" : "none",
                }}
              >
                <source src={ghosteryVideo} type="video/mp4" />
              </video>
            </div>
            <div className={classes.descriptionContainer}>
              <p className={classes.bold}>{activeAdBlockerObj.name}:</p>
              {activeAdBlockerObj.description.map((message, index) => (
                <p className="pt-5" key={index}>
                  {message}
                </p>
              ))}
              {activeAdBlockerObj.video ? null : (
                <button onClick={redirectToWebstore}>
                  Download uBlock Origin
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
