import { colors } from "..";
import logo from "../assets/logo-white.svg";
import hero from "../assets/hero.png";
import CTA from "./CTA";
import bg from "../assets/hero-bg.png";

export default function Home() {
  return (
    <div className="px-16 mx-auto max-w-[2000px] relative box-border overflow-hidden">
      <div className="mx-auto max-w-[1200px] h-[775px] relative">
        <nav className="py-10 flex items-center justify-center sm:justify-between">
          <a href="/">
            <img src={logo} className="w-[200px] object-contain"></img>
          </a>

          <CTA className="hidden sm:flex" size="sm" useColorLogo={true} />
        </nav>

        <div className="flex justify-center items-center md:items-start md:justify-start gap-7 flex-col mt-20">
          <h1 className={`my-0 text-[36px] sm:text-[48px] font-[500] text-white text-center md:text-left`}>
            Try a Greener <br /> Search Engine
          </h1>

          <p className="my-0 leading-7 text-white/80 text-lg text-center md:text-left">
            Reduce the carbon footprint <br /> of your browsing with OpenTabs
          </p>

          <CTA className={"mt-4"} useColorLogo={true} />
        </div>

        <img
          src={hero}
          className="absolute rounded-xl border border-white border-solid shadow-xl top-[160px] hidden md:block right-[-300px] lg:right-[-50px] w-[650px] object-contain -z-10"
        ></img>
      </div>

      <img src={bg} style={{ filter: "hue-rotate(270deg)" }} className="absolute top-[-300px] left-0 z-[-15] w-[2000px]"></img>
    </div>
  );
}
