import { useTranslation } from "react-i18next";
import classes from "./Card.module.css";

const statementTitles = [
  "Griscom et al., 2017",
  "Tangley, 2020",
  "Montaigne, 2019",
];

const Card = (props) => {
  const { t } = useTranslation();

  const description =
    props.title === statementTitles[0] ? (
      <span>
        {t("Forest_conservation_needs")}{" "}
        <strong>
          {t("30x_less_land_to_have_the_same_climate_mitigation")}
        </strong>{" "}
        {t("outcome_than_planting_trees.")}
      </span>
    ) : props.title === statementTitles[1] ? (
      <span>
        {t("Forest_protection_efforts")}{" "}
        <strong>
          {t(
            "help_protect_the_natural_habitat_of_thousands_of_species_and_local_communities"
          )}
        </strong>{" "}
        {t("that_depend_on_natural_resources.")}
      </span>
    ) : (
      <span>
        {t("Forest_conservation_typically_costs")}{" "}
        <strong>{t("half_as_much_as_forest_restoration_efforts")}</strong>{" "}
        {t("extra_1")}
      </span>
    );
  return (
    <div className={classes.card}>
      <p className={`fadeUpAnimation ${classes.description}`} data-scroll>
        {description}
      </p>
      <div className={classes.title}>
        <p className="fadeUpAnimation" data-scroll>
          {props.title}
        </p>
      </div>
    </div>
  );
};

export default Card;
