import CTA_Base from "../../CTA_Base";

export default function CTA({ className = "", style = {}, useColorLogo = false, size = "lg" }) {
  return (
    <CTA_Base
      className={`rounded-md flex cursor-pointer items-center justify-center px-5 py-2.5 text-white gap-3 font-[inherit] text-md border-none
        ${size === "lg" ? "text-lg px-7 py-2.5 gap-4" : "text-md"}
        ${className}`}
      style={{
        filter: "drop-shadow(0px 18px 24px #000)",
        color: !useColorLogo ? "#fff" : "#006548",
        background: !useColorLogo ? "linear-gradient(225deg, #006548, #006548)" : "#fff",
        ...style,
      }}
      logoClassName={`${size === "lg" ? "h-6 w-6" : "h-5 w-5"} object-contain`}
      text="Add to %browser%"
      useColorLogo={useColorLogo}
    ></CTA_Base>
  );
}
