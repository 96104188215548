import { useEffect } from "react";

import chromeWhite from "./V1/assets/chrome-white.png";
import chrome from "./V1/assets/chrome.png";
import edgeWhite from "./V1/assets/edge-white.png";
import edge from "./V1/assets/edge.png";
import firefoxWhite from "./V1/assets/firefox-white.png";
import firefox from "./V1/assets/firefox.png";
import safariWhite from "./V1/assets/safari-white.png";
import safari from "./V1/assets/safari.png";
import { coinisPixels, sendCoinisPixel } from "./CoinisTracking";

export default function CTA_Base({ className, style, text, logoClassName, useColorLogo }) {
  //const extension = useExtension({ browserName: null });

  const extension = {
    browserName: "Chrome",
    href: "https://chromewebstore.google.com/detail/opentabs-save-trees-by-su/igeeighenacaciapkehcacnojlegbnpa",
  };

  const trackButtonClick = () => {
    sendCoinisPixel(coinisPixels.ctaClick);

    const redirect = () => {
      window.location.replace(extension.href);
    };

    setTimeout(redirect, 1000);
  };

  useEffect(() => {
    console.log("extension", extension);
  }, [extension]);

  const logos = {
    colored: {
      Chrome: chrome,
      "Microsoft Edge": edge,
      Safari: safari,
      Firefox: firefox,
    },
    white: {
      Chrome: chromeWhite,
      "Microsoft Edge": edgeWhite,
      Safari: safariWhite,
      Firefox: firefoxWhite,
    },
  };

  return (
    <a className={className} style={style} onClick={() => trackButtonClick()}>
      {extension.browserName && (
        <>
          <img src={logos[useColorLogo ? "colored" : "white"][extension.browserName || "Chrome"]} alt={extension.browserName} className={logoClassName} />
          {text.replace("%browser%", extension.browserName || "Chrome")}
        </>
      )}
    </a>
  );
}
