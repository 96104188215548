import classes from "./RadioButton.module.css";

const RadioButton = ({ name, labelObj: { _id, label }, onSelect }) => {
  return (
    <label htmlFor={name} className={classes.container}>
      <input
        type="radio"
        value={label}
        id={_id}
        name={name}
        onChange={onSelect}
      />
      <span className={classes.checkmark}></span>
      {label}
    </label>
  );
};

export default RadioButton;
