import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i8nextInit";
import "./tailwind-o.css";
import "../src/pages/Welcome/styles.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
