import logo from "../assets/logo.svg";
import whale from "../assets/whale.svg";
import shell from "../assets/shell-2.svg";
import check from "../assets/check.png";
import CTA from "./CTA";
import { colors } from "..";

export default function Home() {
  return (
    <div className="px-8 md:px-16">
      <div className="mx-auto max-w-[1200px] h-[450px] md:h-[550px] relative">
        <nav className="py-10 flex items-center justify-center sm:justify-between">
          <a href="/">
            <img src={logo} className="h-[49px] w-[195px] object-contain"></img>
          </a>
        </nav>

        <div className="flex justify-center items-center gap-7 flex-col mt-10">
          <h1 className={`my-0 text-[32px] sm:text-[48px] md:leading-[4rem] font-[700] ${colors.heading} text-center`}>
            Plant a tree for every <br /> 5 tabs opened
          </h1>

          <CTA className={"mt-4 scale-[0.7] -mx-12 -mb-6 md:mx-0 md:mb-0 md:scale-100"} />

          <div className="flex items-center justify-center gap-3 mr-6 md:mr-0">
            <img src={check} className="w-[30px] h-[30px]"></img>
            <span className="text-lg">Compatible with your browser</span>
          </div>
        </div>

        <img
          src={whale}
          className="absolute bottom-[-40px] right-[10px] md:bottom-[25px] md:block md:right-[75px] w-[70px] md:w-[120px] object-contain -z-10"
        ></img>

        <img
          src={shell}
          className="absolute top-[80px] md:top-[250px] md:block md:right-[-300px] md:left-[0px] w-[70px] md:w-[120px] object-contain -z-10"
        ></img>
      </div>
    </div>
  );
}
