import { useState } from "react";
import { colors } from "..";

export default function Calculator() {
  const [searchesPerDay, setSearchesPerDay] = useState(50);

  return (
    <>
      <section className="mx-auto flex items-center justify-center flex-col gap-8 pb-24 my-24">
        {/* <h1 className={`${colors.heading} text-[30px] md:text-[42px] font-[600]  my-12`}>See How Many Trees You Can Plant</h1> */}

        <p className="flex items-center justify-center gap-5  text-5xl">In a year save up to</p>

        <span className={`${colors.heading} text-8xl mt-10 mb-8 font-[800]`}> {Math.round((searchesPerDay / 5) * 365)} trees 🌳</span>

        <p className={`${colors.heading} text-[2rem]  text-center md:text-left flex items-center justify-center gap-3`}>
          By making{" "}
          <span className={`${colors.heading} text-3xl font-[600]`}>
            {" "}
            <input
              value={searchesPerDay}
              onChange={(e) => setSearchesPerDay(e.target.value)}
              className={`w-20 h-12 font-bold text-4xl text-center font-[inherit] bg-transparent border-0 focus:ring-0 focus:outline-none border-b-2 border-solid border-[${colors.heading}] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${colors.heading}`}
              type="number"
            />
          </span>{" "}
          searches a day 🔎
        </p>
      </section>
    </>
  );
}
