export const reasons = [
    { _id: 1, label: "I don’t like seeing ads" },
    { _id: 2, label: "My bookmarks bar was gone" },
    { _id: 3, label: "Slow loading times" },
    { _id: 4, label: "I don’t like the search engine" },
    { _id: 5, label: "This is temporary, I’ll be back" },
    { _id: 6, label: "Privacy concerns" },
    { _id: 7, label: "I prefer my default new-tab page" },
    { _id: 8, label: "Other" },
  ];
  
  export const usingTime = [
    { _id: 1, label: "Less than a day" },
    { _id: 2, label: "More than a day" },
    { _id: 3, label: "More than a week" },
    { _id: 4, label: "More than a month" },
    { _id: 5, label: "More than 3 months" },
  ];
  
  export const content = [
    {
      _id: 1,
      Message: () => (
        <h2>
          By viewing as little as one ad, you can
          <br />
          save a tree for every 5 tabs you open
          <br />
          <br />
          If you don’t like ads, you can use
          <br />
          OpenTabs with an adblocker and save a<br />
          tree for every 50 tabs you open.
          <br />
          <br />
          You can also save 5 trees for every
          <br />
          search you make using our search bar.
        </h2>
      ),
    },
    {
      _id: 2,
      Message: () => (
        <h2>
          You can enable your bookmarks bar on
          <br />
          OpenTabs by following this
          <br />
          <a
            style={{ color: "#3B4256", textDecoration: "underline" }}
            href="https://opentabs.notion.site/Why-Is-My-Bookmarks-Bar-Missing-2a93b78006474f8c950847dd98de9b8b"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            quick tutorial
          </a>
          .
        </h2>
      ),
    },
    {
      _id: 3,
      Message: () => (
        <h2>
          You can improve the loading times by
          <br />
          removing the OpenTabs background
          <br />
          images. Simply enable Classic mode in
          <br />
          the backgrounds settings.
        </h2>
      ),
    },
    {
      _id: 4,
      Message: () => (
        <h2>
          You can disable OpenTabs Search and
          <br />
          still save trees by opening new tabs! It’s <br />
          the easiest way to make an impact.
        </h2>
      ),
    },
    {
      _id: 6,
      Message: () => (
        <h2>
          We’re a privacy-friendly nonprofit
          <br />
          interested in making an impact, not in
          <br />
          your data. We don’t see your searches
          <br />
          or what you do outside of OpenTabs.
          <br />
          <br />
          What you do online is your business
          <br />
          and yours alone.
        </h2>
      ),
    },
  ];