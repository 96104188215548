import bottom from "../assets/bottom.png";
import CTA from "./CTA";
import logo from "../assets/logo.svg";

export const Footer = () => {
  return (
    <div className="relative w-full max-w-[2000px] mx-auto -mb-2 -mt-[10rem]">
      <img
        src={bottom}
        alt="bottom1"
        className="bottom-0 left-0 w-[100%] max-w-[2000px] z-[-10]"
        style={{
          filter: "hue-rotate(274deg)",
        }}
      />

      <div className="absolute h-full w-full top-0 left-0 z-10 flex  flex-col box-border justify-center items-center pt-32 md:pt-64 px-8">
        <h1 className="text-white text-base lg:text-4xl leading-12 px-12 mb-12 md:text-4xl font-normal text-center max-w-[70rem]">
          Deforestation contributes to the extinction of approximately 137 species of plants, animals, and insects daily.
        </h1>

        <a className="text-white pb-16 text-sm md:text-2xl underline" href="https://wjarr.com/sites/default/files/WJARR-2022-0749.pdf">
          - World Journal of Advanced Research and Reviews
        </a>
        <CTA className="mt-2 md:mt-8 drop-shadow-2xl scale-75 md:scale-100" style={{ filter: "drop-shadow(0px 18px 24px #000)" }} useColorLogo={true} />
      </div>

      <div className="mx-auto max-w-[1200px] absolute bottom-0 left-0 right-0 z-20 flex items-center justify-between py-8 px-8">
        <a href="/">
          <img src={logo} alt="logo" className="h-[24px] md:h-[38px] object-contain" />
        </a>

        <span className="text-white text-xs md:text-sm">All rights reserved &copy; OpenTabs 2024</span>
      </div>
    </div>
  );
};

export default Footer;
