import { Link } from "react-router-dom";
import classes from "./Logo.module.css";
import brandLogo from "../../assets/logos/brand.svg";
import usa from "../../assets/images/usa-flag.png";
import esp from "../../assets/images/esp-flag.png";
import bra from "../../assets/images/bra-flag.png";
import fra from "../../assets/images/fra-flag.png";

export const Logo = (props) => {
  const flagStyle = {
    background: `url(${
      props.language === "English"
        ? usa
        : props.language === "Español"
        ? esp
        : props.language === "Português"
        ? bra
        : fra
    }) no-repeat 7px 5px / 24px`,
  };

  return (
    <header>
      <Link to="/" className={classes.anchorLogo}>
        <img
          src={brandLogo}
          alt="OpenTabs-logo"
          className={classes.brandLogo}
        />
      </Link>
      <div className={classes.translationDropdown}>
        <select
          id="Language-dropdown"
          onChange={props.onChangeLanguage}
          value={props.language}
          style={flagStyle}
        >
          {props.availableLanguages?.map((language) => (
            <option key={language.code}>{language.name}</option>
          ))}
        </select>
      </div>
    </header>
  );
};
