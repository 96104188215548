import classes from "./Selectors.module.css";

const Selector = (props) => {
  let selectorClass = [classes.selector];
  if (props.activeId === props.id) {
    selectorClass.push(classes.active);
  }

  return (
    <div
      className={selectorClass.join(" ")}
      onClick={() => props.selectorClickHandler(props.id)}
    ></div>
  );
};

const Selectors = (props) => {
  const handleClick = (selectedId) => {
    if (selectedId !== props.activeId) {
      props.updateActiveId(selectedId);
    } else {
      return;
    }
  };

  return (
    <div className={classes.selectors}>
      {props.data.map((item) => (
        <Selector
          key={item.id}
          id={item.id}
          activeId={props.activeId}
          selectorClickHandler={handleClick}
        />
      ))}
    </div>
  );
};

export default Selectors;
