import { useEffect, useState } from "react";

let timer1;
let timer2;
let countForBadge = 0;
let countForPopup = 0;

export const CookieScript = () => {
  window.parent.postMessage("hidepopup", "*");

  const [hidePoup,setHidePopup]=useState(false)


  useEffect(()=>{
    if(!hidePoup) return;
    window.parent.postMessage("hidepopup", "*");
  },[hidePoup])

  useEffect(() => {
    document.body.style.position = "relative";
    document.body.style.background = "transparent";
    document.body.style.height = "400px";
    document.body.style.width = "300px";
    timer1 = setInterval(() => {
      const ele = document.getElementById("cookiescript_injected");
      countForPopup++;
      if (ele) {
        ele.style.position = "absolute";
        ele.style.maxWidth = "unset";
        ele.style.maxHeight = "unset";
        ele.style.height = "62%";
        const buttons = document.querySelectorAll("div[role=button]");
        if(buttons.length > 0) window.parent.postMessage("showpopup", "*");
        buttons.forEach((btn) => {
          btn.addEventListener("click", () => {
            window.parent.postMessage("hidepopup", "*");
          });
        });
        document.getElementById("cookiescript_close").addEventListener("click", () => {
          window.parent.postMessage("hidepopup", "*");
        });
        clearInterval(timer1);
      }
      if (countForPopup > 10000) {
        setHidePopup(true)
        clearInterval(timer1);
      }
    });
    timer2 = setInterval(() => {
      countForBadge++;
      const ele = document.getElementById("cookiescript_badge");
      if (ele) {
        ele.style.position = "absolute";
        clearInterval(timer2);
      }
      if (countForBadge > 10000) {
        setHidePopup(true)
        clearInterval(timer2);
      }
    });
  }, []);
  return null;
};
