import seahorse from "../assets/sea-horse.svg";
import turtle from "../assets/turtle.svg";

function Step({ highlighted, text, index }) {
  return (
    <div className="flex flex-col items-center justify-center max-w-[15.5rem] gap-8">
      <div
        className="h-[5rem] w-[5rem] rounded-lg shrink-0 flex items-center justify-center border-solid border-[1px] border-black border-b-[8px]"
        style={{
          background: highlighted ? "linear-gradient(225deg, rgb(73 219 177), rgb(0, 101, 72))" : "#fff",
        }}
      >
        <span
          className="text-4xl font-bold"
          style={{
            color: highlighted ? "#fff" : "#006548",
          }}
        >
          {index + 1}
        </span>
      </div>

      <div className="text-sm text-center text-black/50" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default function HowItWorks() {
  const steps = [
    {
      highlighted: true,
      text: 'Install <b style="color: #006548">OpenTabs</b> - and then keep surfing the web normally, like you do every day.',
    },
    {
      highlighted: false,
      text: "Search with OpenTabs, interact with the ads and click on the tiles on the new tab page",
    },
    {
      highlighted: false,
      text: "We will plant trees from the revenue generated by the ads you interact with!",
    },
  ];

  return (
    <section className="mx-auto flex items-center justify-center flex-col gap-8 pb-8 md:pb-28 relative max-w-[1200px] ">
      <div className="px-8 overflow-scroll max-w-full box-border mt-20">
        <div className="flex items-start justify-start gap-10 md:gap-20 max-w-full pb-16">
          {steps.map(({ highlighted, text }, i) => (
            <Step key={i} highlighted={highlighted} text={text} index={i} />
          ))}
        </div>
      </div>

      <img src={seahorse.src} className="w-[120px] object-contain z-[-10] absolute bottom-[50px] hidden md:block md:bottom-[20px] right-[40px]" />

      <img src={turtle.src} className="w-[140px] object-contain z-[-10] absolute bottom-[50px] hidden md:block md:bottom-[-20px] left-[10px]" />
    </section>
  );
}
