/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

export const JointeroOriginal = () => {
  return (
    <iframe
      width="100%"
      src="https://www.jointero.org/american_elm_tree"
      class="rectangle-iframe"
    ></iframe>
  );
};
