import { useState, useEffect, useRef } from "react";
import { calculateTotalTreeSaved } from "../../containers/TreeCounter/TreeCounter";
import { activeUsersCount } from "../../components/Introduction/Introduction";
import Selectors from "../Selectors/Selectors";
import { SECONDS_PER_TREE_SAVED } from "../../containers/TreeCounter/TreeCounter";
import classes from "./Bird.module.css";
import flyingParrot from "../../assets/videos/parrot-flying.webm";
import flyingParrotGif from "../../assets/videos/parrot-flying.gif";

import { useTranslation } from "react-i18next";
const CO2_STORED_PER_TREE = 0.11;
const playbackRate = 0.3;
const roundOff = 100000;
let treesSaved = calculateTotalTreeSaved();
treesSaved = treesSaved - (treesSaved % roundOff);
let acresSaved = Math.floor(treesSaved / 860);
let emissionsSaved = Math.floor(treesSaved * CO2_STORED_PER_TREE);

export const Bird = (props) => {
  const { t } = useTranslation();

  const data = [
    {
      id: 0,
      timeDescription: `+${activeUsersCount}`,
      contentDescription: t("active_users"),
    },
    {
      id: 1,
      timeDescription: `${SECONDS_PER_TREE_SAVED} ${t("sec")}`,
      contentDescription: t("to_save_a_tree"),
    },
    {
      id: 2,
      timeDescription: `+${acresSaved.toLocaleString()}`,
      contentDescription: "acres saved",
    },
    {
      id: 3,
      timeDescription: `+${emissionsSaved.toLocaleString()}`,
      contentDescription: "tonnes of CO2 eq. stored",
    },
    {
      id: 4,
      timeDescription: `+${treesSaved.toLocaleString()}`,
      contentDescription: "trees saved",
    },
  ];

  const [activeState, setActiveState] = useState(data[0].id);
  const videoRef = useRef();

  useEffect(() => {
    if (props.browser !== "safari")
      videoRef.current.playbackRate = playbackRate;
  }, [props.browser]);

  const updateActiveState = (id) => {
    setActiveState(id);
  };

  const birdVideo = props.isMobile ? null : props.browser === "safari" ? (
    <img src={flyingParrotGif} alt="bird" loading="lazy" />
  ) : (
    <video muted autoPlay loop ref={videoRef}>
      <source src={flyingParrot} type="video/webm" />
    </video>
  );

  return (
    <div className={classes.container}>
      <Selectors
        data={data}
        activeId={activeState}
        updateActiveId={updateActiveState}
      />
      <h1 className="fadeUpAnimation" data-scroll>
        {data[activeState].timeDescription}
      </h1>
      {birdVideo}
      <h2 className="fadeUpAnimation" data-scroll>
        {data[activeState].contentDescription}
      </h2>
    </div>
  );
};
