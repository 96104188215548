import { browsers } from "../Introduction/Introduction";
import classes from "./Badge.module.css";
import badge from "../../assets/images/badge.png";

export const Badge = (props) => {
  return browsers
    .filter((browser) => browser.name === props.browser)
    .map((browser, i) => (
      <a href={browser.link} rel="noopener" key={i}>
        <img id="Green-badge" src={badge} alt="badge" className={classes.badge} />
      </a>
    ));
};
