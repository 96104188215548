export default function Gradient({ gradient }) {
  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-[-100]"
      style={{
        background: gradient,
      }}
    ></div>
  );
}
