import { colors } from "..";
import tweets from "../../tweets.json";
import CTA from "./CTA";

function Tweet({ tweet }) {
  return (
    <div className="flex flex-col bg-white items-start justify-start shadow-2xl rounded-lg px-6 py-4 shrink-0 w-[22rem]">
      <div className="flex items-center justify-start gap-4 mb-2">
        <img src={tweet.icon} alt="Profile" className="w-10 h-10 rounded-full" />

        <div className="flex flex-col gap-1">
          <span className={`${colors.heading} text-[16px] font-medium`}>{tweet.title}</span>
        </div>
      </div>
      <p className="text-black/90 text-[13px] leading-5">{tweet.text}</p>
    </div>
  );
}

export default function Testimonials() {
  return (
    <section className="mx-auto flex items-center justify-center flex-col gap-8 pb-24 p">
      <h1 className={`${colors.heading} text-[30px] md:text-[42px] font-[600]  my-12`}>Testimonials</h1>

      <div className="px-8 overflow-scroll max-w-full box-border">
        <div className="flex items-start justify-start gap-6 max-w-full pb-16">
          {tweets.map((tweet, index) => (
            <Tweet key={index} tweet={tweet} />
          ))}
        </div>
      </div>

      <CTA useColorLogo={true} />
    </section>
  );
}
