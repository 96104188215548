import CTA_Base from "../../CTA_Base";

export default function CTA({ className = "", style = {}, useColorLogo = false, size = "lg" }) {
  return (
    <CTA_Base
      className={`rounded-lg flex cursor-pointer items-center justify-center px-5 py-2.5 text-white gap-3 font-[inherit] text-md border-black border-solid border-[1px] border-b-[8px]
        ${size === "lg" ? "text-2xl px-10 py-6 gap-7" : "text-md"}`}
      style={{
        color: !useColorLogo ? "#fff" : "#216FFB",
        background: !useColorLogo ? "linear-gradient(225deg, rgb(78 225 183), rgb(40 175 137))" : "#fff",
        ...style,
      }}
      logoClassName={`${size === "lg" ? "h-8 w-8" : "h-5 w-5"} object-contain`}
      text="Add to %browser%"
      useColorLogo={useColorLogo}
    ></CTA_Base>
  );
}
