import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FlipNumbers from "react-flip-numbers";
import classes from "./TreeCounter.module.css";
import stroke from "../../assets/images/stroke.svg";

const GLOBAL_TREES_SAVED_TILL_CHANGED_DATE = 8_212_000;
const SAVING_TREES_RATE_CHANGED_DATE = new Date(2022, 4, 3, 0, 0, 0);
export const SECONDS_PER_TREE_SAVED = 25;

export const calculateTotalTreeSaved = () => {
  const initialDate = SAVING_TREES_RATE_CHANGED_DATE;
  const instant = new Date();
  const finalDate = new Date(
    instant.getUTCFullYear(),
    instant.getUTCMonth(),
    instant.getUTCDate(),
    instant.getUTCHours(),
    instant.getUTCMinutes(),
    instant.getUTCSeconds()
  );
  return (
    GLOBAL_TREES_SAVED_TILL_CHANGED_DATE +
    Math.floor((finalDate - initialDate) / 1000 / SECONDS_PER_TREE_SAVED)
  );
};

const addCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trimCommas = (num) => {
  return parseInt(num.replace(/,/g, ""), 10);
};

export const TreeCounter = () => {
  const { t } = useTranslation();
  const [treeSaved, setTreeSaved] = useState(
    addCommas(calculateTotalTreeSaved())
  );

  useEffect(() => {
    // Increase trees saved counter by 1 every 23 seconds
    const incrementInterval = setInterval(() => {
      setTreeSaved((prevValue) => addCommas(trimCommas(prevValue) + 1));
    }, SECONDS_PER_TREE_SAVED * 1000);

    return () => {
      clearInterval(incrementInterval);
    };
  }, []);

  return (
    <div className={classes.treeCounter}>
      <h1
        className={`fadeUpAnimation longerDelay ${classes.bigCounter}`}
        data-scroll
      >
        <FlipNumbers
          numbers={String(treeSaved)}
          height={41}
          width={25}
          duration={1}
          perspective={10000}
          play
        />
      </h1>
      <h1
        className={`fadeUpAnimation longerDelay ${classes.smallCounter}`}
        data-scroll
      >
        <FlipNumbers
          numbers={String(treeSaved)}
          height={27}
          width={17}
          duration={1}
          perspective={10000}
          play
        />
      </h1>
      <p className="fadeUpAnimation longerDelay" data-scroll>
        {t("Trees_Saved_by_OpenTabs_users")}
      </p>
      <div className={classes.strokeContainer}>
        <span className={classes.colorPop}></span>
        <img src={stroke} alt="stroke" loading="lazy" />
      </div>
    </div>
  );
};
