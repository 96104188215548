import React, { useLayoutEffect } from "react";
import owl from "../../assets/images/owl.png";
import GridLayout from "../../components/GridLayout/GridLayout";
import classes from "./Disable.module.css";
import alert from "../../assets/images/alert.png";
import { detectBrowser } from "../../helper/detectBrowser";
import { browsers } from "../../components/Introduction/Introduction";

export const Disable = () => {
  return <GridLayout left={<Left/>} right={<Right />} staticPage={true} />;
};

const Right = () => {
  return <img src={owl} alt="brand logo" loading="lazy" />;
};


const Left = () => {
  const [browserName, setBrowserName] = React.useState("");


  const handleClick = () => {
    const link = (browsers.find(obj=>obj.name.toLowerCase()===browserName.toLowerCase())).link
    if(link){
      window.open(link)
    }else{
      alert("Sorry, we don't have a link for this browser.")
    }
  }

  useLayoutEffect(() => {
    setBrowserName(detectBrowser());
  }, []);
    return (
      <div className={classes.lastStep}>
        <img src={alert} alt="brand logo" loading="lazy" />
        <>
          <h1>
          OpenTabs was not<br/>added correctly.
          </h1>
          <h4>It seems that you accidentally disabled OpenTabs.<br/>{`You can turn it on again in the ${browserName} Web Store.`}</h4>
          <button type="button" onClick={handleClick} className={classes.submit}>
          {`OPEN ${browserName.toUpperCase()} WEB STORE`}
          </button>
        </>
      </div>
    );
  };