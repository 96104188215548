import classes from "./GridLayout.module.css";

const GridLayout = ({ left, right, staticPage, uninstall, start }) => {
  return (
    <div className={`${classes.root} ${staticPage ? classes.static : ""}`}>
      <div className={classes.container}>
        <div
          className={`${classes.left} ${
            uninstall ? classes.uninstallPage : ""
          }`}
        >
          {left}
        </div>
        <div className={classes.right}>
          {right}
          {start && start}
        </div>
      </div>
    </div>
  );
};

export default GridLayout;
