import { useTranslation } from "react-i18next";
import { browsers } from "../Introduction/Introduction";
import Card from "./Card/Card";
import classes from "./Globe.module.css";
import earthRotation from "../../assets/videos/earth-rotation.webm";
import earth from "../../assets/images/earth.png";

const statements = [
  {
    title: "Griscom et al., 2017",
  },
  {
    title: "Tangley, 2020",
  },
  {
    title: "Montaigne, 2019",
  },
];

export const Globe = (props) => {
  const { t } = useTranslation();

  const button = props.isMobile ? (
    <button onClick={props.openModal}>{t("send_to_my_computer")}</button>
  ) : (
    <>
      {browsers
        .filter((browser) => browser.name === props.browser)
        .map((browser, i) => (
          <a href={browser.link} rel="noopener" key={i}>
            <button className={classes.browserButton}>
              <img src={browser.image} alt="brower-logo" />
              {t("add_to")} {browser.name}
              <span>{t("It's_free")}</span>
            </button>
          </a>
        ))}
    </>
  );

  const isSafariBrower = props.browser === "safari";

  const globeVideo = isSafariBrower ? null : (
    <video muted autoPlay loop>
      <source src={earthRotation} type="video/webm" />
    </video>
  );

  const backgroundImageStyle = {
    backgroundImage: `url(${earth})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div
      className={classes.outerContainer}
      style={isSafariBrower ? backgroundImageStyle : null}
    >
      <div className={classes.cardsContainer}>
        {statements.map((statement, i) => (
          <Card key={i} title={statement.title} />
        ))}
      </div>
      {button}
      {globeVideo}
    </div>
  );
};
