import { useTranslation } from "react-i18next";
import classes from "./Attribute.module.css";
import { numberZero, numberOne, numberTwo, numberThree } from "../../assets/utils";

export const Attribute = () => {
  const { t } = useTranslation();

  const attributes = [
    {
      title: t("Hassle_Free"),
      description: t(
        "We_don’t_ask_for_your_time._We_just_want_to_transform_your_daily_activity_of_opening_new_tabs_into_a_force_of_good."
      ),
      digit: numberOne,
    },
    {
      title: t("Radically_Transparent"),
      description: t(
        "We_are_not_here_to_trick_you._Instead,_we_share_our_financial_reports_on_our_website_for_you_to_know_the_details_on_how_we_make_and_spend_money."
      ),
      digit: numberTwo,
    },
    {
      title: t("Power_of_the_Many"),
      description: t(
        "With_OpenTabs_we_want_to_prove_that_individual_actions_can_make_an_impact_and_that_the_power_of_the_many_can_change_the_world."
      ),
      digit: numberThree,
    },
  ];

  return (
    <div className={classes.flexContainer}>
      {attributes.map((attribute, i) => (
        <div className={classes.card} key={i}>
          <div className="fadeUpAnimation" data-scroll>
            <span className={classes.svgNumbers}>
              {numberZero}
              {attribute.digit}
            </span>
            <h3>{attribute.title}</h3>
          </div>
          <p className="fadeUpAnimation" data-scroll>
            {attribute.description}
          </p>
        </div>
      ))}
    </div>
  );
};
