import { useTranslation } from "react-i18next";
import Card from "./Card/Card";
import classes from "./Claim.module.css";
import document from "../../assets/images/document.png";
import speaker from "../../assets/images/speaker.png";
import shield from "../../assets/images/privacy-shield.png";

export const Claim = () => {
  const { t } = useTranslation();

  const cardItems = [
    {
      title: t("Radically_Transparent"),
      description: t(
        "We_publish_our_financial_reports_for_you_to_see_exactly_where_the_income_from_your_tabs_goes."
      ),
      link: "/financials",
      image: document,
      imageClass: "documentImage",
    },
    {
      title: t("Completely_Non-Profit"),
      description: t(
        "We_don't_have_shareholders_to_please_and_do_not_generate_any_profits_or_dividends."
      ),
      link: "https://donorbox.org/opentabs",
      image: speaker,
      imageClass: "speakerImage",
    },
    {
      title: t("Privacy_Friendly"),
      description: t(
        "We_don't_track_your_search_or_browsing_activities._What_you_do_online_is_your_business_and_yours_alone."
      ),
      link: "/privacy",
      image: shield,
      imageClass: "shieldImage",
    },
  ];

  return (
    <div className={classes.componentContainer}>
      <div className={classes.componentHeading}>
        <h2 className="fadeUpAnimation" data-scroll>
          {t("A_new_era_of_non-profit_technology")}
        </h2>
        <h3 className="fadeUpAnimation" data-scroll>
          {t("We_stand_for_a_better_internet")}
        </h3>
      </div>
      <div className={classes.cardsContainer}>
        {cardItems.map((card, i) => (
          <Card
            key={i}
            link={card.link}
            title={card.title}
            description={card.description}
            image={card.image}
            imageClassName={card.imageClass}
          />
        ))}
      </div>
    </div>
  );
};
