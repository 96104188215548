import { useTranslation } from "react-i18next";
import { locomotiveScroll as scroll } from "../../pages/Home/Home";
import classes from "./AboutUs.module.css";
import thumbprint from "../../assets/images/thumb-print.png";

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.flexContainer}>
      <div
        className={`fadeUpAnimation longerDelay ${classes.textContainer}`}
        data-scroll
      >
        <h1>{t("About_Us")}</h1>
        <h4>
          {t(
            "The_world_is_messed_up,_most_people_are_busy_trying_to_make_ends_meet,_companies_are_sketchy,_and_Big_Tech_is_no_longer_concerned_about_“don´t_be_evil”."
          )}
        </h4>
        <p>
          {t(
            "Thinking_of_these_everyday_worries,_we_developed_OpenTabs,_an_award-winning_non-profit_startup_registered_in_Spain,_with_a_product_that_is_hassle-free,_radically_transparent,_and_dependent_on_the_power_of_the_many_to_work."
          )}
        </p>
      </div>
      <img
        src={thumbprint}
        alt="thumbprint-leaves"
        data-scroll
        data-scroll-delay={scroll.delay}
        data-scroll-speed={scroll.speed}
      />
    </div>
  );
};
