import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import {
  About,
  Ads,
  CookieScript,
  Disable,
  Home,
  Home1,
  Install,
  JointeroAd,
  JointeroOriginal,
  Message,
  PrimaryAd,
  Privacy,
  RectangleAd,
  SecondaryAd,
  Terms,
  Uninstall,
} from "./pages";
import { AdTest } from "./pages/AdTest";
import { American_elm_tree } from "./pages/American_elm_tree";
import LP1 from "./pages/LandingPages/V1";
import LP2 from "./pages/LandingPages/V2";
import LP3 from "./pages/LandingPages/V3";
import LP4 from "./pages/LandingPages/V4";
import LP5 from "./pages/LandingPages/V5";
import LP6 from "./pages/LandingPages/V6";
import Welcome from "./pages/Welcome";
import { TrackConversion } from "./pages/LandingPages/TrackConversion";

const App = () => {
  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.src =
  //     "//geo.cookie-script.com/s/cd6f88ba6742f6a96231bd53a9871f0f.js?region=eu";
  //   document.head.appendChild(script);
  // }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/app" component={Home1} />
          <Route path="/about" component={About} />
          {/* <Route path="/financials" component={Financial} /> */}
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />
          {/* <Route path="/referral" component={Referral} /> */}
          <Route path="/ads/three" component={PrimaryAd} />
          <Route path="/ads/two" component={RectangleAd} />
          <Route path="/ads/one" component={SecondaryAd} />
          <Route path="/message" component={Message} />
          <Route path="/ads" component={Ads} />
          <Route path="/uninstall" component={Uninstall} />
          <Route path="/install" component={Install} />
          <Route path="/disable" component={Disable} />
          <Route path="/cookieScript" component={CookieScript} />
          <Route path="/jointero" component={JointeroAd} />
          <Route path="/jointero_original" component={JointeroOriginal} />
          <Route path="/trees" component={AdTest} />
          <Route path="/american_elm_tree" component={American_elm_tree} />
          <Route path="/welcome" component={Welcome} />
          <Route path="/lp/1" component={LP1} />
          <Route path="/lp/2" component={LP2} />
          <Route path="/lp/3" component={LP3} />
          <Route path="/lp/4" component={LP4} />
          <Route path="/lp/5" component={LP5} />
          <Route path="/lp/6" component={LP6} />
          <Route path="/extension/c" component={TrackConversion} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
