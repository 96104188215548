import profile1 from "../../assets/images/testimonial-profile-1.png";
import profile2 from "../../assets/images/testimonial-profile-2.png";
import profile3 from "../../assets/images/testimonial-profile-3.png";
import profile4 from "../../assets/images/testimonial-profile-4.png";
import profile5 from "../../assets/images/testimonial-profile-5.png";
import profile6 from "../../assets/images/testimonial-profile-6.png";
import profile7 from "../../assets/images/testimonial-profile-7.png";
import profile8 from "../../assets/images/testimonial-profile-8.png";
import profile9 from "../../assets/images/testimonial-profile-9.png";
import profile10 from "../../assets/images/testimonial-profile-10.png";
import "./Testimonial.css";

const arrow = (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7L13 1"
      stroke="#27AF89"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const testimonials = [
  {
    name: "Melanie Dsouza",
    feedback:
      "Absolutely amazing! Open Tabs has such a user-friendly interface, a track record, and a beautiful cause!",
    rating: "",
    image: profile1,
  },
  {
    name: "Cedric Fortuin",
    feedback: "10/10 best Chrome extension ever!",
    rating: "",
    image: profile2,
  },

  {
    name: "Voidey Bear :D",
    feedback:
      "It's amazing! I open a lot of tabs on a daily so this is super nice to be able to maybe help a cause! I love me some good oxygen!!! And beautiful trees uwu",
    rating: "",
    image: profile3,
  },
  {
    name: "Melanie Dsouza",
    feedback:
      "Love the idea and cause of OpenTabs! Everything is so accessible also, i don't have to add any other extensions or shortcuts because it is all already available!",
    rating: "",
    image: profile4,
  },
  {
    name: "Akshat Thakur",
    feedback:
      "Great initiative. It really is what the world needs right now. Totally support OpenTabs. Will be recommending this to everyone.!",
    rating: "",
    image: profile5,
  },
  {
    name: "Katerina Rettino",
    feedback:
      "So easy to use and doesn't change the way I browse at all which is nice! I know other extensions that are similar but have their own search engines instead of allowing you to choose your search engine.",
    rating: "",
    image: profile6,
  },
  {
    name: "Clary Pu16",
    feedback:
      "It's a genius design and looks so good! I love seeing the number of trees go up - I've only been using it for a couple of days and I can proudly say I've saved three trees. Maybe I should open more tabs!",
    rating: "",
    image: profile7,
  },
  {
    name: "Derek S.",
    feedback:
      "Surprised by how much passively I can help out through viewing something as non-consequential as ads in my new tabs.",
    rating: "",
    image: profile8,
  },
  {
    name: "Zabphelia",
    feedback:
      "Beautiful format. Super easy to use. I like all the customizable features it has, that's what really hooked me",
    rating: "",
    image: profile9,
  },
  {
    name: "Adriana",
    feedback:
      "AMAZING!! It's super similar to any other browser but the best part is I am helping out the environment and I can change my browser home screen image! Love it!",
    rating: "",
    image: profile10,
  },
];

const CarouselCard = (props) => {
  const { name, feedback, image, rating } = props.testimonial;
  return (
    <>
      <div className="feedback">
        <p className="fadeUpAnimation" data-scroll>
          {feedback}
        </p>
      </div>
      <div className="userDetails">
        <img src={image} alt="profile" loading="lazy" />
        <div className="userSubDetail">
          <h1 className="fadeUpAnimation" data-scroll>
            {name}
          </h1>
          <p>{rating}</p>
        </div>
      </div>
    </>
  );
};

export const Testimonial = () => {
  const cards = document.querySelectorAll(".carouselCard");
  const cardsSize = cards.length;

  let previousCard = 10;
  let currentCard = 1;
  let nextCard = 2;

  const onPrevious = () =>
    currentCard > 0 ? slideCards(currentCard - 1) : slideCards(cardsSize - 1);

  const onNext = () =>
    currentCard < cardsSize - 1 ? slideCards(currentCard + 1) : slideCards(0);

  const slideCards = (number) => {
    currentCard = number;
    previousCard = currentCard - 1;
    nextCard = currentCard + 1;

    for (let i = 0; i < cardsSize; i++) {
      cards[i].classList.remove("active");
      cards[i].classList.remove("previous");
      cards[i].classList.remove("next");
    }

    if (nextCard === cardsSize) {
      nextCard = 0;
    }

    if (previousCard === -1) {
      previousCard = cardsSize - 1;
    }

    cards[currentCard].classList.add("active");
    cards[previousCard].classList.add("previous");
    cards[nextCard].classList.add("next");
  };

  return (
    <>
      <div className="carouselContainer">
        <div className="carouselCard previous">
          <CarouselCard testimonial={testimonials[9]} />
        </div>
        <div className="carouselCard active">
          <CarouselCard testimonial={testimonials[0]} />
        </div>
        <div className="carouselCard next">
          <CarouselCard testimonial={testimonials[1]} />
        </div>
        {testimonials.slice(2, 9).map((testimonial, index) => (
          <div className="carouselCard" key={index}>
            <CarouselCard testimonial={testimonial} />
          </div>
        ))}
      </div>
      <div className="buttonContainer">
        <button
          className="carouselButton"
          aria-label="previous"
          onClick={onPrevious}
        >
          <span className="leftCarouselButton">{arrow}</span>
        </button>
        <button className="carouselButton" aria-label="next" onClick={onNext}>
          <span className="rightCarouselButton">{arrow}</span>
        </button>
      </div>
    </>
  );
};
