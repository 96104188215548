import { colors } from "..";
import logo from "../assets/logo.svg";
import hero from "../assets/hero.png";
import creatures from "../assets/sea-creatures.png";
import underwater from "../assets/underwater.png";
import CTA from "./CTA";

export default function Home() {
  return (
    <div className=" px-16">
      <div className="mx-auto max-w-[1200px] h-[850px] relative">
        <nav className="py-10 flex items-center justify-center sm:justify-between">
          <a href="/">
            <img src={logo} className="h-[49px] w-[195px] object-contain"></img>
          </a>

          <CTA className="md:mr-16 hidden sm:flex" size="sm" />
        </nav>

        <div className="flex justify-center items-center md:items-start md:justify-start gap-7 flex-col mt-20">
          <h1 className={`my-0 text-[36px] sm:text-[48px] font-[600] ${colors.heading} text-center md:text-left`}>
            Save trees by <br />
            surfing the web
          </h1>

          <p className={`my-0 leading-7 ${colors.heading} text-lg text-center md:text-left`}>
            Use the web as usual, and we'll <br />
            take care of the rest!
          </p>

          <CTA className={"mt-4"} />
        </div>

        {/* <img src={underwater} className="absolute top-[-80px] md:right-[-500px] hidden md:block lg:right-[-300px] w-[1000px] object-contain -z-10"></img> */}

        {/* <img
          src={desktop.src}
          className="absolute top-[125px] hidden md:block right-[-300px] lg:right-[-260px] w-[750px] object-contain -z-10"
        ></img> */}

        <img src={hero} className="absolute rounded-xl shadow-xl top-[180px] hidden md:block right-[-300px] lg:right-[-50px] w-[650px] -z-10"></img>

        <img
          src={creatures}
          style={{
            filter: "hue-rotate(274deg)",
          }}
          className="w-[550px] object-contain contrast-[2]  absolute bottom-[30px] md:bottom-[-50px] left-[-200px] -z-10"
        ></img>
      </div>
    </div>
  );
}
