import { useEffect } from "react";

export const CoinisTracking = ({ landingPageId = "" }) => {
  const coinisTrackingScript = `(function() {
        let lpId = '${landingPageId}';
        let cmpId = '9q3CzNYBMWI2yDn9cgPOxksNJLyMWFTvc49F6ccsMfs';
            
        function setCookie(name, value) {
            document.cookie = name + "=" + value + "; path=/ ; SameSite=Lax; domain=.opentabs.org" + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        }
    
        function getCookie(name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i].trim();
                if (c.indexOf(nameEQ) == 0)
                    return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
    
        function saveClickId(clickId) {
            setCookie('click_id', clickId);
        }
    
        function getClickId() {
            return getCookie('click_id');
        }
        
        const params = new URLSearchParams(window.location.search);
        
        if (params.has("cmp_id")) {
            cmpId = params.get('cmp_id');
            params.delete("cmp_id");
        }
    
        if (params.has("qs1") || lpId !== "") {
            params.set('qs1', lpId);
        }
        
        setCookie('cmp_id', cmpId);
    
        const cmp_url = "https://t.opentabs.org/" + cmpId + "?" + params.toString();
        var xhr = new XMLHttpRequest();
        xhr.open("GET", cmp_url, true);
        xhr.withCredentials = true;//
    
        xhr.responseType = "json";
        xhr.onload = function () {
            var status = xhr.status;
            if (status === 200) {
                let click_id = xhr.response["click_id"];
                saveClickId(click_id);
            }
        };
        xhr.send();
    
        window.getClickId = getClickId;
    })();`;

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = coinisTrackingScript;
    document.head.appendChild(script);
  }, []);

  return <></>;
};

export const sendCoinisPixel = (pixel = null, params = {}) => {
  const endpoint = `https://p.opentabs.org`;
  const clickId = window.getClickId();

  if (!clickId) return;

  const img = new Image();
  img.src = `${endpoint}/?click_id=${clickId}${pixel ? `&p=${pixel}` : ""}${Object.keys(params)
    .map((key) => `&${key}=${params[key]}`)
    .join("")}`;
};

export const coinisPixels = {
  conversion: "",
  install: "p1",
  search: "p2",
  uninstall: "p3",
  onboarding: "p4",
  adClick: "p5",
  ctaClick: "p6",
};
