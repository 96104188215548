/* eslint-disable no-undef */
import React, { useEffect, useLayoutEffect } from "react";
import monkey from "../../assets/images/monkey.png";
import RadioButton from "../../components/Ui/RadioButton/RadioButton";
import classes from "./Uninstall.module.css";
import { content, reasons, usingTime } from "./data";
import { detectBrowser } from "../../helper/detectBrowser";
import { browsers } from "../../components/Introduction/Introduction";
import { InlineWidget } from "react-calendly";

import "./important.css";
import { coinisPixels, sendCoinisPixel } from "../LandingPages/CoinisTracking";

export const Uninstall = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDataSaved, setIsDataSaved] = React.useState(false);
  const [selecedReason, setSelecedReason] = React.useState({});

  console.log(!isNextAvailable(selecedReason.id) && isDataSaved);

  useEffect(() => {
    sendCoinisPixel(coinisPixels.uninstall);
  }, []);

  return (
    <div className={classes.uninstall}>
      <div className={classes.container}>
        <div className={classes.left}>
          <Left
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isDataSaved={isDataSaved}
            setIsDataSaved={setIsDataSaved}
            selecedReason={selecedReason}
            setSelecedReason={setSelecedReason}
          />
        </div>
        <div className={classes.right}>
          <div className={classes.hidden}>
            <InlineWidget url="https://calendly.com/umberto-greco/opentabs?hide_gdpr_banner=1=1" />
          </div>
          <img className={classes.monkey} src={monkey} alt="brand logo" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

const Step = ({ data, selectedData, setData, handleStepper, heading, text, otherHandler, otherValue, loading, isDataSaved, showTextfield }) => {
  const [browserName, setBrowserName] = React.useState("");
  useLayoutEffect(() => {
    setBrowserName(detectBrowser());
  }, []);
  return (
    <div className={classes.leftContainer}>
      <h1>{heading}</h1>
      <h2>{text}</h2>
      <div className={classes.radioContainer}>{data && data.map((obj) => <RadioButton onSelect={setData} name="reason" labelObj={obj} key={obj._id} />)}</div>
      {showTextfield && selectedData.id && (
        <input
          type="text"
          name="reason"
          placeholder={selectedData.id === "8" ? "Your response" : "Tell us more (optional)"}
          className={classes.inputField}
          onChange={otherHandler}
          value={otherValue}
          autoComplete="off"
        />
      )}
      <button type="button" className={classes.submit} onClick={handleStepper}>
        {loading ? <Loading /> : isDataSaved ? "Thank you for the feedback!" : "SEND FEEDBACK"}
      </button>
      <a href={browserName !== "" ? browsers.find((obj) => obj.name.toLowerCase() === browserName.toLowerCase())?.link : "#"} className={classes.link}>
        {`Uninstalled by mistake? Add OpenTabs back to ${browserName}`}
      </a>
    </div>
  );
};

const Left = ({ activeStep, setActiveStep, setIsDataSaved, isDataSaved, selecedReason, setSelecedReason }) => {
  const [otherReason, setOtherReason] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleReason = (event) => {
    setSelecedReason({ value: event.target.value, id: event.target.id });
  };
  const handleOtherReason = (event) => {
    if (loading) return;
    setOtherReason(event.target.value);
  };

  const handleTime = (event) => {
    setSelectedTime({ value: event.target.value, id: event.target.id });
  };

  const handleStepper = () => {
    if (activeStep === 0 && !selecedReason.id) return alert("Please select a reason");
    if (activeStep === 0 && selecedReason.id == "8" && !otherReason) return alert("Please enter your reason");
    if (activeStep === 1 && selecedReason.id && !selectedTime.id) return alert("Please select an option");
    if ((selecedReason.id === "8" && otherReason && selectedTime.id) || (selecedReason.id && selectedTime.id)) {
      if (loading || isDataSaved) return;
      setLoading(true);
      let data = {
        reason: selecedReason.id === "8" ? otherReason : otherReason ? `${selecedReason.value}:${otherReason}` : selecedReason.value,
        timeUsed: selectedTime.value,
      };
      const successCB = () => {
        setLoading(false);
        if (isNextAvailable(selecedReason.id)) {
          setActiveStep(activeStep + 1);
        } else {
          setIsDataSaved(true);
        }
      };
      const errorCB = (err) => {
        setLoading(false);
        console.log(err);
      };
      postData(data, successCB, errorCB);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep}>
        <Step
          data={reasons}
          handleStepper={handleStepper}
          selectedData={selecedReason}
          setData={handleReason}
          heading="We’re sorry to see you go!"
          text="Please help us improve OpenTabs by filling out this quick survey. We
          appreciate every chance to do better."
          otherHandler={handleOtherReason}
          otherValue={otherReason}
          loading={false}
          showTextfield={true}
        />
        <Step
          data={usingTime}
          handleStepper={handleStepper}
          selectedData={selectedTime}
          setData={handleTime}
          heading="One last question 🙏"
          text="For how long have you been using OpenTabs?"
          loading={loading}
          isDataSaved={isDataSaved}
        />
        <LastStep reason={selecedReason} />
      </Stepper>
    </>
  );
};

const LastStep = ({ reason }) => {
  const [browserName, setBrowserName] = React.useState("");

  useLayoutEffect(() => {
    setBrowserName(detectBrowser());
  }, []);

  const handleClick = () => {
    const link = browsers.find((obj) => obj.name.toLowerCase() === browserName.toLowerCase()).link;
    if (link) {
      window.open(link);
    } else {
      alert("Sorry, we don't have a link for this browser.");
    }
  };
  const contentObj = content.find((obj) => {
    return obj._id === Number(reason.id);
  });
  const { Message } = contentObj;
  return (
    <div className={classes.lastStep}>
      {Message && (
        <>
          <h1>Wait, did you know? </h1>
          {<Message />}
          <p className={classes.link}>Will you give OpenTabs another try?</p>
          <button type="button" onClick={handleClick} className={classes.submit}>
            ADD OPENTABS BACK TO CHROME
          </button>
        </>
      )}
    </div>
  );
};

const Stepper = ({ children, activeStep }) => {
  const childrens = React.Children.toArray(children);
  const currentChild = childrens[activeStep];
  return <>{currentChild}</>;
};

const isNextAvailable = (number) => [1, 2, 3, 4, 6].includes(Number(number));

const postData = async (data, successCB, errorCB) => {
  const response = await fetch("https://app.opentabs.org/feedback", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  console.log(json);
  if (json.Success === true) {
    // console.log("here")
    successCB();
  } else {
    errorCB(json.error);
  }
};

const Loading = () => {
  return <i className="fa fa-2xl fa-spinner fa-spin"></i>;
};
