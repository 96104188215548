import CTA_Base from "../../CTA_Base";

export default function CTA({ className = "", style = {}, useColorLogo = false, size = "lg" }) {
  return (
    <CTA_Base
      className={`rounded-md flex cursor-pointer items-center justify-center px-5 py-2.5 text-white gap-3 font-[inherit] text-md border-none ${
        size === "lg" ? "text-xl px-6 py-3 gap-4" : "text-md"
      }`}
      style={{
        filter: "drop-shadow(0px 12px 16px rgba(0,0,0,0.25))",
        color: !useColorLogo ? "#fff" : "#000",
        background: !useColorLogo ? "linear-gradient(225deg, rgb(40 175 137), rgb(40 175 137))" : "#fff",
        ...style,
      }}
      logoClassName={`${size === "lg" ? "h-6 w-6" : "h-5 w-5"} object-contain`}
      text="Open Tabs and Plant Trees"
      useColorLogo={useColorLogo}
    ></CTA_Base>
  );
}
