import { useTranslation } from "react-i18next";
import { locomotiveScroll as scroll } from "../../pages/Home/Home";
import classes from "./Quote.module.css";
import forest from "../../assets/images/forest-heart.png";

export const Quote = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.outerContainer}>
      <div
        data-scroll
        data-scroll-delay={scroll.delay}
        data-scroll-speed={scroll.speed}
      >
        <div className={classes.container}>
          <img src={forest} alt="forest" />
          <div className={classes.textContainer}>
            <h1>{t("It_Takes_a_Village")}</h1>
            <h3>
              {t(
                "When_people_choose_OpenTabs,_they’re_saying_yes_to_good_and_acknowledging_the_power_of_the_many"
              )}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
