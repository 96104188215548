import React, { useLayoutEffect } from "react";
import GridLayout from "../../components/GridLayout/GridLayout";
import installScene from "../../assets/images/installScene.png";
import greenLogo from "../../assets/logos/brand-coloured_all_green.svg";
import tick from "../../assets/images/tick.png";
import classes from "./Install.module.css";
import cursor from "../../assets/images/cursor.png";
import { detectBrowser } from "../../helper/detectBrowser";
import { coinisPixels, sendCoinisPixel } from "../LandingPages/CoinisTracking";

export const Install = () => {
  return <GridLayout left={<Left />} right={<Right />} staticPage={true} start={<Startup />} />;
};

const Right = () => {
  return <img src={installScene} alt="brand logo" loading="lazy" />;
};

const Left = () => {
  // const handleClick = () => {};

  return (
    <div className={classes.lastStep}>
      <img src={greenLogo} alt="brand logo" loading="lazy" />
      <>
        <h1>
          <span className={classes.green}>Congratulations!</span>
          <br />
          OpenTabs is your new tab page.
        </h1>
        {[
          "Save a tree for every 10 tabs you open.",
          "Say yes to good and the power of the many.",
          "Support a new generation of nonprofit green technology.",
        ].map((para, index) => {
          return (
            <div key={index} className={classes.bullets}>
              <img src={tick} alt="" />
              <p>{para}</p>
            </div>
          );
        })}
        {/* <button type="button" className={classes.submit} onClick={handleClick}>
          Start Tabbing!
        </button> */}
      </>
    </div>
  );
};

const Startup = () => {
  const [browserName, setBrowserName] = React.useState("");

  useLayoutEffect(() => {
    setBrowserName(detectBrowser());
    sendCoinisPixel(coinisPixels.install);
  }, []);

  return (
    <div className={classes.innerContainer}>
      <h4>Let's make sure your extension stays active</h4>
      <h5>
        {`When ${browserName} asks you if you meant to change the page,`}
        <br />
        {`click "Keep it" to start using OpenTabs.`}
      </h5>
      <div className={classes.innerWhite}>
        <p className={classes.innerWhitePara}>Did you mean to change this page?</p>
        <div className={classes.innerWhiteSkeleton}></div>
        <div className={classes.innerInner}>
          <div className={classes.innerBtnContainer}>
            <div className={classes.innerBtn}>
              Keep it
              <img src={cursor} className={classes.cursor} alt="" />
            </div>
          </div>
          <div className={classes.innerSkeletonButton}></div>
        </div>
      </div>
    </div>
  );
};
