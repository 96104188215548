import bottom from "../assets/bottom.png";
import CTA from "./CTA";
import logo from "../assets/oceanhero-white.png";
import hero from "../assets/bottom-hero.png";
import { colors } from "..";
import dolphin from "../assets/dolphin.svg";
import shell from "../assets/shell.svg";
import octopus from "../assets/octopus.svg";
import crab from "../assets/crab.svg";

export const BottomHero = () => {
  return (
    <div className="relative w-full max-w-[1200px] mx-auto -mb-2 ">
      <div className="flex flex-col justify-center items-center max-w-[880px] mx-auto gap-8 px-8">
        <h1 className={`${colors.heading} text-[30px] md:text-[42px] my-8 font-bold text-center`}>Just surf the web like you do every day</h1>

        <img src={hero.src} className="w-[400px] md:w-[650px] " />

        <h1 className={`${colors.heading} text-[30px] my-8 md:text-[42px] font-bold text-center`}>
          We will plant trees from the revenue generated by the ads you interact with!
        </h1>

        <CTA className="scale-[0.8]" />

        <h1 className={`${colors.heading} text-[30px] md:text-[42px] my-8 font-bold text-center`}>Ready to Help Us Save the Trees?</h1>
      </div>

      {/* <img src={dolphin} className="absolute top-[-90px] md:top-[-70px] left-[40px] md:left-[-20px] w-[70px] md:w-[120px]" />

      <img src={crab} className="absolute top-[100px] right-[20px] w-[70px] md:w-[120px]" />

      <img src={shell} className="absolute top-[500px] md:top-[300px] left-[10px] md:left-[-60px] w-[70px] md:w-[120px]" />

      <img src={octopus} className="absolute bottom-[120px] md:bottom-[100px] right-[20px] w-[70px] md:w-[120px]" /> */}
    </div>
  );
};

export default BottomHero;
