import { colors } from "..";
import desktop from "../assets/hero.png";
import graphic from "../assets/graphic-2.png";

function Step({ highlighted, text, index }) {
  return (
    <div className="flex flex-row items-bottom justify-center max-w-[25rem] gap-12">
      <div
        className="h-[3rem] w-[3rem] rounded-lg shrink-0 shadow-xl flex items-center justify-center"
        style={{
          background: highlighted ? "linear-gradient(225deg, #006548, #006548)" : "#fff",
        }}
      >
        <span
          className="text-2xl font-bold"
          style={{
            color: highlighted ? "#fff" : "#006548",
          }}
        >
          {index + 1}
        </span>
      </div>

      <div className="text-sm text-left text-black/80 leading-7 mt-3" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default function HowItWorks() {
  const steps = [
    {
      highlighted: true,
      text: 'Install <b style="color: #006548">OpenTabs</b> - and then keep surfing the web normally, like you do every day.',
    },
    {
      highlighted: false,
      text: "Search with OpenTabs, interact with the ads and click on the tiles on the new tab page",
    },
    {
      highlighted: false,
      text: "We will plant trees from the revenue generated by the ads you interact with!",
    },
  ];

  return (
    <section className="mx-auto flex items-center justify-between flex-col gap-8 pb-24 relative max-w-[1200px] ">
      <h1 className={`${colors.heading} my-12 text-[30px] md:text-[42px] font-medium`}>How It Works</h1>

      <div className="px-8 flex items-center justify-between w-full box-border mt-8">
        <div className="shadow-2xl -mx-8 bg-blue-300 rounded-xl">
          <img src={desktop} className="  rounded-lg w-[550px]" />
        </div>

        <div className="flex items-start justify-start flex-col gap-10 md:gap-20 max-w-full pb-16">
          {steps.map(({ highlighted, text }, i) => (
            <Step key={i} highlighted={highlighted} text={text} index={i} />
          ))}
        </div>
      </div>

      <img src={graphic.src} className="absolute right-[-100px] w-[150px] top-[-80px]" />
    </section>
  );
}
