import logo from "../assets/logo-white.svg";

export const Footer = () => {
  return (
    <div
      className="relative w-full mt-16"
      style={{
        background: "linear-gradient(90deg, #006548, #006548)",
      }}
    >
      <div className="max-w-[1200px] mx-auto flex-col md:flex-row gap-5 flex items-center justify-between py-12 px-12">
        <img src={logo} className="h-[42px]" />

        <span className="text-white">All rights reserved &copy; OpenTabs 2024</span>
      </div>
    </div>
  );
};

export default Footer;
