import { useTranslation } from "react-i18next";
import arrow from "../../assets/images/arrow.png";
import browserWindow1 from "../../assets/images/browserWindow-1.png";
import fullStar from "../../assets/images/full-star.svg";
import halfStar from "../../assets/images/half-star.svg";
import chrome from "../../assets/logos/chrome.svg";
import edge from "../../assets/logos/edge.svg";
import treeGrowingGif from "../../assets/videos/tree-growing.gif";
import treeGrowing from "../../assets/videos/tree-growing.webm";
import { TreeCounter } from "../../containers";
import { locomotiveScroll as scroll } from "../../pages/Home/Home";
import classes from "./Introduction.module.css";
import { coinisPixels, sendCoinisPixel } from "../../pages/LandingPages/CoinisTracking";

const numberOfFullStars = 4;
export const activeUsersCount = "40.000";
export const browsers = [
  {
    name: "chrome",
    image: chrome,
    link: "https://chrome.google.com/webstore/detail/opentabs/igeeighenacaciapkehcacnojlegbnpa",
  },
  // {
  //   name: "firefox",
  //   image: firefox,
  //   link: "https://addons.mozilla.org/en-US/firefox/addon/opentabs",
  // },
  // {
  //   name: "safari",
  //   image: safari,
  //   link: "https://apps.apple.com/es/app/opentabs/id1607542045?l=en&mt=12",
  // },
  {
    name: "edge",
    image: edge,
    link: "https://microsoftedge.microsoft.com/addons/detail/opentabs/oaljniopiimfdkdoppegcoikkaaingln",
  },
];

export const Introduction = (props) => {
  const { t } = useTranslation();

  const browser = browsers.find((browser) => browser.name === props.browser) || browsers[0];

  const button = props.isMobile ? (
    <div className={classes.buttonContainer}>
      <button onClick={props.openModal}>{t("send_to_my_computer")}</button>
    </div>
  ) : (
    <div className={classes.buttonContainer}>
      {
        <a
          href={browser.link || browsers[0].link}
          rel="noopener"
          onClick={(e) => {
            e.preventDefault();

            sendCoinisPixel(coinisPixels.ctaClick);

            setTimeout(() => {
              window.location.href = browser.link || browsers[0].link;
            }, 1000);
          }}
        >
          <button className={classes.browserButton}>
            <img src={browser.image} alt="brower-logo" />
            {t("add_to")} {browser.name}
          </button>
        </a>
      }
      <img src={arrow} alt="arrow" />
      <span>{t("It's_free")}</span>
    </div>
  );

  const animationVideo = props.isMobile ? null : props.browser === "safari" ? (
    <img src={treeGrowingGif} alt="tree" loading="lazy" />
  ) : (
    <video muted autoPlay loop>
      <source src={treeGrowing} type="video/webm" />
    </video>
  );

  return (
    <div className={classes.introductionContainer}>
      <div className={classes.introductionMain}>
        <div className={classes.headingFont}>
          <div className="fadeUpAnimation longerDelay" data-scroll>
            <p className={classes.introductionText}>{t("you_open_tabs")}</p>
            <h1 className={classes.introductionHeading}>{t("we_save_trees")}</h1>
          </div>
          {button}
        </div>
        <div className={`whiteText fadeUpAnimation ${classes.introductionStatistics}`} data-scroll>
          <div className={classes.ratings}>
            {[...Array(numberOfFullStars)].map((star, i) => (
              <img src={fullStar} alt="star" key={i} />
            ))}
            <img src={halfStar} alt="star" />
            <span>{t("4.96_stars_out_of_5")}</span>
          </div>
          <p>
            +{activeUsersCount} {t("active_users")}
          </p>
          <div className={classes.availableBrowsers}>
            <span>{t("Also_available_on")}</span>
            {browsers
              .filter((browser) => browser.name !== props.browser)
              .map((browser, i) => (
                <a href={browser.link} key={i}>
                  <img src={browser.image} alt="brower-logo" key={i} />
                </a>
              ))}
          </div>
        </div>
        <TreeCounter />
      </div>
      <div className={classes.imageContainer}>
        <img src={browserWindow1} loading="lazy" alt="browser-window" data-scroll data-scroll-delay={scroll.delay} data-scroll-speed={scroll.speed} />
        <div className={classes.animationContainer}>{animationVideo}</div>
      </div>
    </div>
  );
};
