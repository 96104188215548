import { useTranslation } from "react-i18next";
import { locomotiveScroll as scroll } from "../../pages/Home/Home";
import classes from "./Method.module.css";
import browserWindow3 from "../../assets/images/browserWindow-3.png";

export const Method = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.descriptionContainer}>
        <div className={classes.descriptionElement}>
          <h2 className="fadeUpAnimation" data-scroll>
            {t("How_does_it_work?")}
          </h2>
          <p className="fadeUpAnimation" data-scroll>
            {t(
              "OpenTabs_has_a_small_ad_in_the_corner_that_generates_money_every_time_someone_opens_a_new_tab."
            )}
          </p>
        </div>
        <div
          className={classes.descriptionImageContainer}
          data-scroll
          data-scroll-delay={scroll.delay}
          data-scroll-speed={scroll.speed}
        >
          <img
            src={browserWindow3}
            alt="browser-window"
            className={classes.browserWindow}
          />
          <div className={classes.subDescriptionElement}>
            <p>
              {t(
                "We_are_partnering_with_Rainforest_Trust_to_protect_our_rainforests_by_saving_1_tree_for_every_5_tabs_users_open."
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
