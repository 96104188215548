import { useState } from "react";
import { colorPalette } from "../../../pages/Financial/Financial";
import classes from "./Chart.module.css";

const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(
    `--${variableName}`
  );
};

const setCSSVariableValue = ({ variableName, value }) => {
  document.documentElement.style.setProperty(`--${variableName}`, value);
};

const RADIUS = getCSSVariableValue("radius");
const STROKEWIDTH = 40;
const GREEN = colorPalette.green;
const BLUE = colorPalette.blue;
const ORANGE = colorPalette.orange;
const PINK = colorPalette.pink;
const GREY = colorPalette.grey;
const DARK = colorPalette.dark;
const YELLOW = colorPalette.yellow;
const LIGHT_BLUE = colorPalette.lightBlue;
const xCoordinate = "50%";
const yCoordinate = "50%";

export const Chart = (props) => {
  const [climateChangeHoveredArc, setClimateChangeHoveredArc] = useState("");
  const [operatingExpenseHoveredArc, setOperatingExpenseHoveredArc] =
    useState("");
  const [climateChangeVisibility, setClimateChangeVisibility] = useState(false);
  const [operatingExpenseVisibility, setOperatingExpenseVisibility] =
    useState(false);

  const monthlyFinance = props.dataset.find(
    (data) => data.month === props.selectedMonth
  );
  const {
    numericCost,
    climateChangeFundBreakdown,
    operatingExpensesBreakdown,
  } = monthlyFinance;

  const { climate, operation } = numericCost;

  const { rainforestConservation, greenInvestment, microloansIssued } =
    climateChangeFundBreakdown;

  const {
    reserves,
    personnel,
    freelancer,
    office,
    serverAndSoftware,
    miscellaneous,
    travel,
    taxes,
  } = operatingExpensesBreakdown;

  setCSSVariableValue({
    variableName: "rainforestConservationPercentage",
    value: rainforestConservation?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "greenInvestmentPercentage",
    value: greenInvestment?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "microloansIssuedPercentage",
    value: microloansIssued?.percent || 0,
  });

  setCSSVariableValue({
    variableName: "reservesPercentage",
    value: reserves?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "personnelPercentage",
    value: personnel?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "freelancerPercentage",
    value: freelancer?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "officePercentage",
    value: office?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "serverAndSoftwarePercentage",
    value: serverAndSoftware?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "miscellaneousPercentage",
    value: miscellaneous?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "travelPercentage",
    value: travel?.percent || 0,
  });
  setCSSVariableValue({
    variableName: "taxesPercentage",
    value: taxes?.percent || 0,
  });

  const showClimateChangePercentage = (event) => {
    const hoveredElement = event.target.getAttribute("name");
    setClimateChangeVisibility(true);
    setClimateChangeHoveredArc(hoveredElement);
  };

  const hideClimateChangePercentage = () => setClimateChangeVisibility(false);

  const showOperatingExpensePercentage = (event) => {
    const hoveredElement = event.target.getAttribute("name");
    setOperatingExpenseVisibility(true);
    setOperatingExpenseHoveredArc(hoveredElement);
  };

  const hideOperatingExpensePercentage = () =>
    setOperatingExpenseVisibility(false);

  const ClimateChangeDescriptionContainer = () => {
    const arcName = climateChangeVisibility
      ? props.dataDescription[climateChangeHoveredArc]?.title
      : props.dataDescription.climateChangeFund?.title;
    const arcDescription = climateChangeVisibility
      ? props.dataDescription[climateChangeHoveredArc]?.content
      : props.dataDescription.climateChangeFund?.content;
    const cost = climateChangeVisibility
      ? climateChangeFundBreakdown[
          climateChangeHoveredArc
        ]?.numeric.toLocaleString()
      : climate.toLocaleString();

    return (
      <>
        <div className={classes.descriptionContainer}>
          <div className={classes.descriptionHeader}>
            <h3>{arcName}</h3>
            <span>{cost}€</span>
          </div>
          <p>{arcDescription}</p>
        </div>
      </>
    );
  };

  const climateChangeChart = (
    <div className={classes.container}>
      <h1>
        {climateChangeVisibility &&
          `${climateChangeFundBreakdown[climateChangeHoveredArc]?.percent}%`}
      </h1>
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle
          className={classes.donutElement}
          name="rainforestConservation"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={GREEN}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showClimateChangePercentage}
          onMouseLeave={hideClimateChangePercentage}
        />
        <circle
          className={classes.donutElement}
          name="greenInvestment"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={BLUE}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showClimateChangePercentage}
          onMouseLeave={hideClimateChangePercentage}
        />
        <circle
          className={classes.donutElement}
          name="microloansIssued"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={ORANGE}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showClimateChangePercentage}
          onMouseLeave={hideClimateChangePercentage}
        />
      </svg>
    </div>
  );

  const OperatingExpenseDescriptionContainer = () => {
    const arcName = operatingExpenseVisibility
      ? props.dataDescription[operatingExpenseHoveredArc]?.title
      : props.dataDescription.operatingExpensesBreakdown?.title;
    const arcDescription = operatingExpenseVisibility
      ? props.dataDescription[operatingExpenseHoveredArc]?.content
      : props.dataDescription.operatingExpensesBreakdown?.content;
    const cost = operatingExpenseVisibility
      ? operatingExpensesBreakdown[
          operatingExpenseHoveredArc
        ]?.numeric.toLocaleString()
      : operation.toLocaleString();

    return (
      <>
        <div className={classes.descriptionContainer}>
          <div className={classes.descriptionHeader}>
            <h3>{arcName}</h3>
            <span>{cost}€</span>
          </div>
          <p>{arcDescription}</p>
        </div>
      </>
    );
  };

  const operatingExpenseChart = (
    <div className={classes.container}>
      <h1>
        {operatingExpenseVisibility &&
          `${operatingExpensesBreakdown[operatingExpenseHoveredArc]?.percent}%`}
      </h1>
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle
          className={classes.secondDonutElement}
          name="reserves"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={GREEN}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="personnel"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={BLUE}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="freelancer"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={ORANGE}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="office"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={PINK}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="serverAndSoftware"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={GREY}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="miscellaneous"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={DARK}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="travel"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={YELLOW}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
        <circle
          className={classes.secondDonutElement}
          name="taxes"
          fill="none"
          r={RADIUS}
          cx={xCoordinate}
          cy={yCoordinate}
          stroke={LIGHT_BLUE}
          strokeWidth={STROKEWIDTH}
          onMouseEnter={showOperatingExpensePercentage}
          onMouseLeave={hideOperatingExpensePercentage}
        />
      </svg>
    </div>
  );

  return (
    <div>
      <div className={classes.outerContainer}>
        {climateChangeChart}
        <ClimateChangeDescriptionContainer />
      </div>
      <div className={classes.outerContainer}>
        {operatingExpenseChart}
        <OperatingExpenseDescriptionContainer />
      </div>
    </div>
  );
};

export default Chart;
