import { useEffect, useState } from "react";
import logo from "./logo-white.svg";
import { coinisPixels, sendCoinisPixel } from "../LandingPages/CoinisTracking";

const Welcome = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isEdge, setIsEdge] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    const isEdge = /Edg/.test(navigator.userAgent);
    const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    const isFirefox = /Firefox/.test(navigator.userAgent);
    setIsEdge(isEdge);
    setIsSafari(isSafari);
    setIsFirefox(isFirefox);
  }, []);

  const links = [
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Privacy",
      path: "/privacy",
    },
    {
      name: "Terms",
      path: "/terms",
    },
  ];

  useEffect(() => {
    // Set title
    document.title = "OpenTabs";

    // Set the link tags
    const link = document.createElement("link");
    link.rel = "preconnect";
    link.href = "https://fonts.googleapis.com";
    document.head.appendChild(link);

    const link2 = document.createElement("link");
    link2.rel = "preconnect";
    link2.href = "https://fonts.gstatic.com";
    link2.crossOrigin = "true";
    document.head.appendChild(link2);

    const link3 = document.createElement("link");
    link3.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap";
    link3.rel = "stylesheet";
    document.head.appendChild(link3);

    sendCoinisPixel(coinisPixels.install);
  }, []);

  return (
    <div className={"mainContainer"}>
      <div className="mainContainerWrapper">
        <nav className="pt-8 pb-12 h-[120px] flex items-center justify-center box-border mb-auto">
          <ul className="flex items-center justify-center list-none gap-20 my-0 p-0">
            <a href="/">
              <img src={logo} alt="Ocean Hero" className="h-[3rem] w-auto" />
            </a>
            {links.map((link) => {
              return (
                <li className="hidden md:block opacity-70 hover:opacity-100 transition-all underline text-white text-lg">
                  <a className="text-white focus:text-white" href={link.path}>
                    {link.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={"mainContainerWrapperInner"}>
          <h1>Thanks for installing OpenTabs!</h1>

          {!isFirefox && <p>Please open a new tab and click {isEdge ? '"Keep Changes"' : '"Keep it"'}</p>}

          <button onClick={() => (window.location.href = "https://opentabs.org/")}>Learn about OpenTabs!</button>
          {isFirefox && (
            <>
              <p className={`explanation`}>Change your default search engine to OpenTabs for easier use</p>
              <video src={"/change-it.mp4"} className={`keepItImageFF`} draggable="false" autoPlay muted loop />
              <p className={`explanation`}>Open a new tab and click “Keep Changes”</p>
            </>
          )}
          <video src={"/keep-it.mp4"} className={isFirefox ? `keepItImageFF` : `keepItImage`} draggable="false" autoPlay muted loop />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
