/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import imagesLoaded from "imagesloaded";
import LocomotiveScroll from "locomotive-scroll";
import "../../locomotive-scroll.css";
import classes from "./Home.module.css";
import { Navbar, Projection } from "../../containers";
import { Logo, Introduction, Recognition, Description, Method, Abstraction, Claim, Footer, Globe, Modal, Badge, Testimonial } from "../../components";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CoinisTracking } from "../LandingPages/CoinisTracking";

export const languages = [
  { name: "English", code: "en", dir: "ltr", active: true },
  { name: "Español", code: "es", dir: "ltr", active: false },
  { name: "Português", code: "pt", dir: "ltr", active: false },
  { name: "Français", code: "fr", dir: "ltr", active: false },
];

export const locomotiveScroll = { delay: "0.04", speed: "0.5" };
export let userDeviceDetails = navigator.userAgent;

export const isMobile = (userDeviceDetails) => {
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobile = regexp.test(userDeviceDetails);
  return isMobile;
};

export const getBrowser = (userDeviceDetails) => {
  let userAgentString = userDeviceDetails;

  let chromeAgent = userAgentString.indexOf("Chrome") > -1;
  let edgeAgent = userAgentString.indexOf("Edg") > -1;
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Chrome since it also matches Edge
  if (chromeAgent && edgeAgent) chromeAgent = false;
  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  return chromeAgent ? "chrome" : edgeAgent ? "edge" : firefoxAgent ? "firefox" : safariAgent ? "safari" : "chrome";
};

export const getTranslateY = (string) =>
  string?.includes("matrix3d") ? +string?.split(",")?.slice(-3)[0]?.split("-")[1] || 0 : +string?.split(",")?.slice(-1)[0]?.split(")")[0]?.split("-")[1] || 0;

export const Home = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [browserType, setBrowserType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCode = localStorage.getItem("i18nextLng") || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(languages.find((language) => language.code === selectedCode).name);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const locomotiveScrollRef = useRef();

  let { search } = useLocation();

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: locomotiveScrollRef.current,
      smooth: true,
    });

    imagesLoaded(locomotiveScrollRef.current, { background: true }, function () {
      scroll.update();
    });

    const navbarScrollVisibilityInterval = setInterval(() => {
      const refStyles = locomotiveScrollRef.current?.style?.msTransform || "";
      const translateY = getTranslateY(refStyles);
      setIsNavbarVisible(translateY >= 80);
    }, 2000);

    return () => {
      clearInterval(navbarScrollVisibilityInterval);
      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    setIsMobileDevice(isMobile(userDeviceDetails));
    if (!isMobileDevice) {
      setBrowserType(getBrowser(userDeviceDetails));
    }
  }, []);

  useEffect(() => {
    if (!search.includes("utm")) return;
    if (localStorage.channel) return;
    const utm = search.split("=")[1];
    if (!utm) return;
    localStorage.channel = utm;
  }, []);

  const changeLanguage = (event) => {
    const targetLanguage = event.target.value;
    setSelectedLanguage(targetLanguage);
    const targetCode = languages.find((language) => language.name === targetLanguage).code;
    i18next.changeLanguage(targetCode);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <>
      {/* <Helmet>
        <meta http-equiv="refresh" content="0; URL=https://www.jointero.org" />
      </Helmet> */}

      <Modal show={isModalOpen} close={toggleModal} />
      <Badge browser={browserType} />
      <div id="locomotiveScrollRef" ref={locomotiveScrollRef}>
        <CoinisTracking landingPageId="" />
        <Navbar isVisible={isNavbarVisible} isMobile={isMobileDevice} openModal={toggleModal} browser={browserType} />
        <section className={`hero ${classes.backgroundGradient}`}>
          <div className={classes.backgroundTexture}>
            <div className={classes.topContainer}>
              <Logo availableLanguages={languages} language={selectedLanguage} onChangeLanguage={changeLanguage} />
              <Introduction isMobile={isMobileDevice} openModal={toggleModal} browser={browserType} />
            </div>
            <Recognition />
          </div>
          <div className={classes.transparency}></div>
        </section>
        <section>
          <Description />
          <Method />
        </section>
        <section className={classes.gradient}>
          <div className={classes.alignment}>
            <Abstraction />
          </div>
          <div className={classes.backgroundImage}>
            <Globe isMobile={isMobileDevice} openModal={toggleModal} browser={browserType} />
            <Projection />
          </div>
          <Testimonial />
          <div className={classes.footerGradientTexture}>
            <Claim />
            <div className={classes.fade}>
              <Footer availableLanguages={languages} language={selectedLanguage} onChangeLanguage={changeLanguage} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
