import classes from "./PrivacyPolicy.module.css";

const contactUsLink = "https://opentabs.contactin.bio/";
const advertisingOptOutLink = "https://optout.networkadvertising.org/?c=1";

export const PrivacyPolicy = () => {
  return (
    <div className={classes.container}>
      <h1>OpenTabs is a Privacy Friendly Non-Profit</h1>
      <div>
        <p>We're interested in making an impact, not in your data.</p>
      </div>
      <div>
        <h2>1. WE DON'T SPY ON YOU</h2>
        <p>We do not eavesdrop or collect any of your web activity or search history. What you do online is your business and yours alone.</p>
      </div>
      <div>
        <h2>2. WE DON'T CREATE PERSONAL PROFILES</h2>
        <p>
          We only collect anonymous and aggregated data and don't create personal profiles of you based on your behaviour, online activity, or search history.
        </p>
      </div>
      <div>
        <h2>3. WE DON'T SELL YOUR DATA</h2>
        <p>Many web services collect user data in order to sell it without asking your permission. We don't sell any of your data to third party companies.</p>
      </div>
      <div>
        <h1>Want to dive into the fine print?</h1>
        <p>See the in-depth legal version of our privacy policy, with detailed information on how we handle your data.</p>
      </div>
      <div>
        <h1>Privacy Policy</h1>
        <p>Last updated: June 17, 2020</p>
      </div>
      <div>
        <p>Greco Paro Association is the not-for-profit creator and owner of OpenTabs, located in Calle Hermosilla 48, 1º Derecha, 28001, Madrid.</p>
      </div>
      <div>
        <p>Your privacy is very important to us. At OpenTabs, we have a few fundamental principles:</p>
        <ul>
          <li>We don't ask you for personal information we don't need.</li>
          <li>We don't share your personal information with anyone except to provide our Services, comply with the law, or protect our rights.</li>
          <li>We don't store personal information unless required for the ongoing operation of one of our Services.</li>
        </ul>
        <p>
          Below is our privacy policy which incorporates these principles. This document applies to OpenTabs ("Greco Paro", “Greco Paro Association",
          "OpenTabs", "us", "our", or "we") and is part of OpenTabs's Terms of Service. By using opentabs.org, app.opentabs.org, or other *.opentabs.io
          subdomains or subdirectories (collectively, the "Website"), you agree to the terms of this Privacy Policy and the Terms of Service. We collectively
          refer to the Website and its related services, apps, and websites as our "Services".
        </p>
      </div>
      <div>
        <p>
          If you have any questions, please{" "}
          <a href={contactUsLink} target="_blank" rel="noopener noreferrer">
            contact us
          </a>
          .
        </p>
      </div>
      <div>
        <h2>Basis to Collect and Use Personal Data</h2>
        <p>
          We process personal data consistent with our disclosures in this Privacy Policy. We process personal data on the following legal bases: (1) with your
          consent; (2) as necessary to perform our agreement to provide Services; and (3) as necessary for our legitimate interests in providing the Services
          where those interests do not override your fundamental rights and freedoms related to data privacy.
        </p>
      </div>
      <div>
        <h2>Data Collected</h2>
        <p>We may collect and store different types of data:</p>
        <ul>
          <li>
            <strong>Anonymous device and behavioral data:</strong> We collect anonymous data from every visitor of the Website to monitor traffic and fix bugs.
            For example, we collect information like web requests, the data sent in response to such requests, the browser type, device information, and a
            timestamp for the request. This data is collected with legitimate interest to maintain and develop our product.
          </li>
          <li>
            <strong>Information you provide to us:</strong> We save information related to your behavior on OpenTabs, such as the the number tabs you open, your
            account settings, and the users you refer OpenTabs. In order to be able to save your profile and the settings associated with it, we ask you to log
            in and provide certain personal information, such as an email address. These are all essential data for providing our Services.
          </li>
          <li>
            <strong>Cookie and local browser storage:</strong> To allow login features, we use cookies and local browser data storage to store session
            information. You can block or delete cookies and local browser data storage and still use the Website, although you may then be asked for your
            username and password each time you open our page.
          </li>
          <li>
            <strong>Ad Targeting, Selection, and Delivery:</strong> When you use our Services, third parties that provide advertising, to the Services, may
            collect or receive information about you including through the use of cookies. These third parties may use your information to provide you with
            advertising that is based on your interests and to measure and analyze ad performance, on our Services or other websites or platforms, and combine
            it with information collected across different websites, online services, and other devices. These third parties' use of your information will be
            based on their own privacy policies. You can opt out of interest-based targeted advertising for some advertising partners{" "}
            <a href={advertisingOptOutLink} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </li>
          <li>
            <strong>Data from contracted partners:</strong> We work with partners who provide hardware and software services, such as as cloud storage and
            server services. We may store data collected by third party companies with whom we have contracted when it is with legitimate interest to maintain
            and develop our product.
          </li>
        </ul>
      </div>
      <div>
        <p>
          You are able to request we delete your account and remove any personal data associated with your account. To do so, please{" "}
          <a href={contactUsLink} target="_blank" rel="noopener noreferrer">
            contact us
          </a>{" "}
          and we will follow up as soon as possible.
        </p>
      </div>
      <div>
        <h2>Children's Information</h2>
        <p>
          The Services are not intended for children under 13 years of age. OpenTabs does not knowingly collect personal information from children under 13
          years of age. If you are under 13 years of age, do not provide personal information to OpenTabs without providing us with consent from your parents.
          If we discover that a child under the age of 13 has provided us with personal information and we do not have parental consent, we will immediately
          delete that child's information. If you believe that company has been provided with the personal information of a child under the age of 13 without
          parental consent, please notify us immediately.
        </p>
      </div>
      <div>
        <h2>Sharing of Data</h2>
        <p>We do not share information you have provided to us without your consent, unless:</p>
        <ul>
          <li>doing so is appropriate to carry out your own request.</li>
          <li>doing so helps us provide our Services.</li>
          <li>we believe it's needed to enforce our Terms of Service, or that it's legally required.</li>
          <li>
            we believe it's needed to enforce our Terms of Service, or that it's legally required.we believe it's needed to detect, prevent or address fraud,
            security, or technical issues.
          </li>

          <li>otherwise protect our property, legal rights, or that of others.</li>
        </ul>
        <p>
          To provide our Services, we may share data with third-party companies (for example, software providers and cloud storage services) with whom we've
          contracted. These companies are restricted from using the data in any other way than to provide services to us.
        </p>
      </div>
      <div>
        <p>We employ industry-standard techniques to protect against unauthorized access of data that we store.</p>
      </div>
      <div>
        <p>
          We may choose to buy or sell assets. In these types of transactions, user information is typically one of the transferred business assets. Moreover,
          if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets
          that is transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of us or our assets may continue
          to use your personal information as set forth in this policy.
        </p>
      </div>
      <div>
        <h2>Changes to the Privacy Policy</h2>
        <p>
          We may amend this Privacy Policy from time to time. The use of the information we collect now is subject to the Privacy Policy in effect at the time
          such information is used. A user is bound by any changes to the Privacy Policy when he or she uses the Services after such changes have been first
          posted.
        </p>
      </div>
      {/* <div>
        <p>
          If you have any questions or concerns, please{" "}
          <a href={contactUsLink} target="_blank" rel="noopener noreferrer">
            contact us
          </a>
          .
        </p>
      </div> */}
    </div>
  );
};
