import { useTranslation } from "react-i18next";
import classes from "./Branches.module.css";
import rightBranch from "../../assets/images/tree-right-branch.png";
import leftBranch from "../../assets/images/tree-left-branch.png";

export const Branches = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          src={rightBranch}
          alt="tree-right-branch"
          className={classes.rightBranch}
        />
        <img
          src={leftBranch}
          alt="tree-left-branch"
          className={classes.leftBranch}
        />
      </div>
      <div className={classes.header}>
        <h1 className="fadeUpAnimation" data-scroll>
          {t("Our_Founding_Team")}
        </h1>
      </div>
    </div>
  );
};
