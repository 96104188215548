import { colors } from "..";
import creatures from "../assets/sea-creatures-2.png";

function Step({ highlighted, text, index }) {
  return (
    <div className="flex flex-col items-center justify-center max-w-[15.5rem] gap-8">
      <div
        className="h-[5rem] w-[5rem] rounded-lg shrink-0 shadow-2xl flex items-center justify-center"
        style={{
          background: highlighted ? "#fff" : "#fff",
        }}
      >
        <span
          className="text-4xl font-bold"
          style={{
            color: highlighted ? "#28af89" : "#28af89",
          }}
        >
          {index + 1}
        </span>
      </div>

      <div className="text-sm text-center text-white" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default function HowItWorks() {
  const steps = [
    {
      highlighted: true,
      text: 'Install <b style="color: #fff">OpenTabs</b> - and then keep surfing the web normally, like you do every day.',
    },
    {
      highlighted: false,
      text: "Search with OpenTabs, interact with the ads and click on the tiles on the new tab page",
    },
    {
      highlighted: false,
      text: "We will plant trees from the revenue generated by the ads you interact with!",
    },
  ];

  return (
    <section className="mx-auto flex items-center justify-center flex-col gap-8 pb-24 relative max-w-[1200px] ">
      <h1 className={`${colors.heading} text-[30px] md:text-[42px] font-[600] my-12`}>How It Works</h1>

      <div className="px-8 overflow-scroll max-w-full box-border mt-8">
        <div className="flex items-start justify-start gap-10 md:gap-20 max-w-full pb-16">
          {steps.map(({ highlighted, text }, i) => (
            <Step key={i} highlighted={highlighted} text={text} index={i} />
          ))}
        </div>
      </div>

      <img src={creatures.src} className="w-[550px] contrast-200 object-contain z-[-10] absolute top-[-250px] hidden md:block md:top-[-180px] right-[-240px]" />
    </section>
  );
}
